import React,{ Fragment, useEffect } from "react"
import { createBrowserRouter,Navigate,
         RouterProvider, useLocation } from "react-router-dom";
import "./App.css"
import "preline/preline";
import "preline/dist/preline";
import "aos/dist/aos.css";
import AOS from "aos";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthProvider, useAuth } from "features/contexts/AuthContext";
import PurchasedPlans  from "@admin/AdminRoutes";
import SuperAdminRouteConfig from "@superadmin/SuperAdminRoutes";
import TermsConditions from "@pages/TermsConditions";
import ForgotPassword from "@pages/ForgotPassword";
import PrivacyPolicy from "@pages/PrivacyPolicy";
import PageNotFound from "@pages/PageNotFound";
import SuperAdminRootLayout from "@superadmin";
import RefundPolicy from "@pages/RefundPolicy";
import LandingPage from "@pages/LandingPage";
import AdminRootLayout from "@admin";
import Signup from "@pages/Signup";
import Login from "@pages/Login";

const HomePage = ({ loading,isAuthenticated,isSystemAdmin }) => {
  const location = useLocation();

  if (loading) return <div>Loading...</div>; // Show loading state

  // If not authenticated and trying to access dashboard sub-routes, redirect to login
  if (!isAuthenticated && location.pathname !== '/') {
    return <Navigate to="/login" replace />;
  }
  
  const AdminCheck = isSystemAdmin ? <SuperAdminRootLayout /> : <AdminRootLayout />

  return isAuthenticated ? AdminCheck : <LandingPage />;
};

function RouterConfiguration() {
  const {isAuthenticated,loading,user} = useAuth();
  
  const CHILD_ROUTES = user?.is_system_admin ? SuperAdminRouteConfig : PurchasedPlans()

  const routes =createBrowserRouter([
    { 
      path: "/",
      element:  <HomePage isAuthenticated={isAuthenticated} isSystemAdmin={user?.is_system_admin} loading={loading}/>,
      children: CHILD_ROUTES
    },
    {
      path: "/login",
      element: <Login />
    },
    {
      path: "/forgot-password",
      element: <ForgotPassword />
    },
    {
      path: "/signup",
      element: <Signup />
    },
    {
      path: "/terms-and-conditions",
      element: <TermsConditions />
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />
    },
    {
      path: "/refund-policy",
      element: <RefundPolicy />
    },
    {
      path: "*",
      element: <PageNotFound />
    }
  ]);
 
    useEffect(()=>{
      AOS.init({
      duration: 1750,
      easing: "ease-out-cubic",
      once: false,
      anchorPlacement: "top-center"
    });
    const handleNavigationChange = async(state) => {

      await import('preline/preline')

      window.HSStaticMethods?.autoInit()
      document.body.style.zoom = "1"; // This will zoom in the page.
    };

    // The `onUpdate` method will trigger on navigation updates
    const unsubscribe = routes.subscribe(handleNavigationChange);

    // Cleanup the subscription when the component unmounts
    return () => {
      unsubscribe();
    };
  },[routes])

  return (
    <Fragment>
      <ToastContainer />
      <RouterProvider router={routes}/>
    </Fragment>
  )
}

function App(){

  return(
      <AuthProvider>
        <RouterConfiguration />
      </AuthProvider>
  )
}
export default App
