import React from "react"
import HeroBiryani from "@images/hero_biryani.png";
import msg1 from "@images/msg1.png";
import msg2 from "@images/msg2.png";
import msg3 from "@images/msg3.png";
import msg4 from "@images/msg4.png";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
        <section id="hero" className="mx-auto max-lg:min-h-[90vh] lg:w-[80%] max-lg:mx-2 relative  top-20 grid grid-flow-row lg:grid-flow-col grid-cols-1 lg:grid-cols-2">
            <div className="lg:max-w-[45vw] lg:py-24 lg:px-1">
                <h1 className="text-[2rem] leading-tight font-bold">
                    Elevate your <span className="text-[#C92727]">CATERING</span><br/>
                    with Catermate, your<br />
                    intelligent <span className="text-[#C92727]">AI PARTNER.</span>
                </h1>
                <p className="my-6 text-[1.3rem]">Enhance your catering business with cutting-edge <br className="max-lg:hidden" />AI technology.</p>
                <Link className="text-white text-[1.5rem] bg-red-700 rounded-full py-4 px-5 my-5" to="/login">
                    Subcsribe Now
                </Link>
            </div>
            <div className="lg:max-w-[43vw] p-7 relative ">
                <img src={msg1} alt="msg1" className="scale-[0.2] max-w-[22rem] h-[10rem] aos-init  absolute z-20 left-[7%] top-[10%]" data-aos="fade-down"/>
                <img src={msg2} alt="msg2" className="scale-[0.2] max-w-[22rem] h-[10rem] aos-init  absolute z-20 right-[10%] top-[15%]" data-aos="fade-down" data-aos-delay="1000"/>
                <img alt="biryani" src={HeroBiryani} className="relative bottom-0 right-0 md:scale-75  max-2xl:-scale-75 z-10"/>
                <img src={msg3} alt="msg3" className="scale-[0.1] max-w-[15rem] h-[5rem] aos-init  absolute z-20 left-[17%] bottom-[25%]" data-aos="fade-down" data-aos-delay="1000"/>
                <img src={msg4} alt="msg4" className="scale-[0.2] max-w-[22rem] h-[10rem] aos-init  absolute z-20 right-[7%] bottom-[10%]" data-aos="fade-down"/>
            </div>
        </section>
  )
}

export default Hero