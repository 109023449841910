import React, { Fragment, useState,useEffect } from 'react'
import { FileUpload, StdButton,SelectReact } from '@components/UtilityComponents'
import Pagination from '@components/Pagination'
import { TableCellWithToolTip } from '@components/Table'
import { Toaster } from '@constants/appConstants'
import axiosInstance from 'apis/config'

const ticketOptions = [
  {label: "Whatsapp related issue",value:"whatsapp"},
  {label: "Payment related issue",value:"payment"},
  {label: "This App related issue",value:"app-related"},
  {label:"Others",value:"others"}
]
const columns=[{name: "Ticket Id",key:"id"},{name: "Ticket Type",key:"ticket_type"},{name: "Status",key:"status"}]

const AdminRaiseTicket = () => {
  const [ticketForm,setTicketForm] = useState({
    ticketType: "",
    description:"",
    images: ""
  })
  
  const [ticketsList,setTicketsList] = useState([]);
  const [currentPage,setCurrentPage] = useState(1);
  const [pageSize,setPageSize] = useState(10);
  const [totalCount,setTotalCount] = useState(0);
  useEffect(()=>{
    axiosInstance.get("order/tickets",{
        params: {
            Limit: pageSize,
            Offset: ((currentPage-1)*pageSize),
        }
    })
    .then((data)=>{
        if(data[0]?.total_count){
            const totalRows  = data[0].total_count;
            setTicketsList(data);
            setTotalCount(totalRows)
        }else{
            Toaster("error",data)
        }
    })
    .catch((err)=>Toaster("error",err))
  },[currentPage,pageSize]);

  return (
    <Fragment>
      <style>
        {
          `
        #raise-ticket textarea {
            border-radius: 20px;
            padding: 14px;
            font-size: 16px;
            min-height: 4rem;
            border: 1px solid #d3d3d3;
            box-shadow: 0 0 5pt 0.5pt #D3D3D3;
            outline: none;
            margin: 0.5rem 1rem;
          }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
            }
            #raise-ticket input[type=number] {
            -moz-appearance: textfield;
            }
          `
        }
      </style>
        <div  className="flex justify-between w-[80%] items-center mx-auto mt-7">
            <p className="text-2xl font-bold place-content-start">Raise Ticket</p>
        </div>
        <div className="mx-auto w-[85%]" id="raise-ticket">
          <form className='grid grid-cols-2' onChange={(e)=>e.preventDefault()} onSubmit={(e)=>e.preventDefault()}>
        <div className='w-full p-7'>
          <SelectReact
            label="Select Ticket Type"
            options={ticketOptions}
            value={ticketForm.ticketType}
            onChange={(value)=>setTicketForm((prev)=>({...prev,ticketType:value}))}
            isLoading={false}
            hasMore={false}
            isClearable={false}
          />
          <textarea
              placeholder="Description"
              rows="6"
              className="w-[95%] resize-none block"
          />
          </div>
          <div >
            <FileUpload />
          </div>
          <StdButton className={"mx-auto w-fit px-4 py-2 text-lg col-span-2"}>
            Submit
          </StdButton>
          </form>
          <div className=" w-[80%] mx-auto my-7">
            <div className="rounded-2xl relative z-30 overflow-x-hidden max-h-[30vh] overflow-y-scroll shadow-md">
                <table className="rounded-2xl table-fixed relative min-w-full">
                    <thead className="rounded-2xl">
                        <tr className="bg-[#FFC092] rounded-2xl sticky top-0">
                            {
                                columns.map((col)=>(
                                    <TableCellWithToolTip key={col.name} 
                                        className="p-4 text-center truncate whitespace-nowrap overflow-hidden max-w-[150px]"
                                        content={col.name} header/>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            ticketsList.map((row,rowIndex)=>(
                                <tr key={row.id} className={`p-2 ${rowIndex%2 === 0 && " bg-white "}`}>
                                    {
                                        columns.map((col)=>(
                                            <TableCellWithToolTip key={col.key} 
                                            className="p-4 text-center truncate whitespace-nowrap overflow-hidden max-w-[150px]"
                                            content={row[col.key]} />
                                        ))
                                    }
                                </tr>
                            ))
                        }
                    </tbody>
                    <tfoot>
                    <tr className="bg-white border-t-[1px] border-gray-300 sticky bottom-0">
                    <td className='text-center p-4' colSpan={columns.length}>
                        <Pagination
                            currentPage={currentPage}
                            totalCount={totalCount}
                            pageSize={pageSize}
                            onPageSizeChange={setPageSize}
                            onPageChange={page => setCurrentPage(page)}
                        />
                    </td>
                    </tr>
                </tfoot>
                </table>
            </div>
            
        </div>
        </div>
    </Fragment>
  )
}

export default AdminRaiseTicket