import { 
    DashboardIcon,FullMenuICon,ChatHistoryIcon, 
    DishesIcon, FixedMenuIcon, IngredientsIcon, 
    LeadsIcon,ManagerDetailsIcon, RaiseTicketIcon, 
    UpComingOrdersIcon, VendorIcon
   } from "@images/sidebarIcons";
import AdminDashboard from "@admin/AdminDashboard";
import AdminFullMenu from "@admin/AdminFullMenu";
import AdminRaiseTicket from "@admin/AdminRaiseTicket";
import AdminFixedMenu from "@admin/AdminFixedMenu";
import AdminIngredients from "@admin/AdminIngredients";
import AdminManager from "@admin/AdminManager";
import AdminVendor from "@admin/AdminVendor";
import AdminLead from "@admin/AdminLead";
import AdminUpcomingOrders from "@admin/AdminUpcomingOrders";
// import AdminChatHistory from "@admin/AdminChatHistory";
import AdminProfile from "@admin/AdminProfile";
import AdminDishes from "@admin/AdminDishes";
import AdminSubscription from "@admin/AdminSubscription";
import PageNotFound from "@pages/PageNotFound";
import { useAuth } from "features/contexts/AuthContext";
import { useMemo } from "react";
import { Link } from "react-router-dom";

export  const AdminSideBarMenus=[
    {
      menu: "Dashboard",
      link: "/",
      icon: <DashboardIcon />
    },
    {
      menu: "Menu Management",
      children: [
        {
          menu: "Dishes",
          link: "/dishes",
          icon: <DishesIcon />
        },
        {
          menu: "Ingredients",
          link: "/ingredients",
          icon: <IngredientsIcon />
        },
        {
          menu: "Full Menu",
          icon: <FullMenuICon />,
          link: "/full-menu"
        },
        {
          menu: "Fixed Menu",
          link: "/fixed-menu",
          icon: <FixedMenuIcon />
        },
      ]
    },
    {
      menu: "Business Management",
      children: [
        {
          menu:"Manager Details",
          link: "/managers",
          icon: <ManagerDetailsIcon />
        },
        {
          menu: "Vendor",
          link: "/vendors",
          icon: <VendorIcon />
        }
      ]
    },
    {
      menu: "Order Management",
      children: [
        {
          menu: "Leads",
          link: "/leads",
          icon: <LeadsIcon />
        },
        {
          menu: "Upcoming Orders",
          link: "/upcoming-orders",
          icon: <UpComingOrdersIcon />
        },
        {
          menu: "Chat History",
          link: "/chat-history",
          icon: <ChatHistoryIcon />
        }
      ]
    },
    {
      menu: "Raise Ticket",
      link: "/raise-ticket",
      icon: <RaiseTicketIcon />
    }
  ]
  export const AdminProfileMenus=[
    {
      menu: "Profile",
      link: "/profile"
    },
    {
      menu: "Subscriptions",
      link: "/subscriptions"
    },
    {
      menu: "Logout",
      link: "/login"
    }
  ];
export const AdminRoutesConfig=[
  { path: "/",
    index: true,
    element:  <AdminDashboard />,
    requiredPlan: "Elite",
  },
  {
    path: "raise-ticket",
    element:  <AdminRaiseTicket />,
    requiredPlan: "Premium",
  },
  {
    path: "full-menu",
    element:  <AdminFullMenu />,
    requiredPlan: "Premium",
  },
  {
    path: "fixed-menu",
    element:  <AdminFixedMenu />,
    requiredPlan: "Premium"
  },
  {
    path: "ingredients",
    element:  <AdminIngredients />,
    requiredPlan: "Elite"
  },
  {
    path: "managers",
    element:  <AdminManager />,
    requiredPlan: "Elite",
  },
  {
    path: "vendors",
    element:  <AdminVendor />,
    requiredPlan: "Elite",
  },
  {
    path: "leads",
    element:  <AdminLead />,
    requiredPlan: "Premium",
  },
  {
    path: "upcoming-orders",
    element:  <AdminUpcomingOrders />,
    requiredPlan: "Elite"
  },
  {
    path: "chat-history",
    element: <div>Page Under Construction</div>, //<AdminChatHistory />,
    requiredPlan: "Elite"
  },
  {
    path: "profile",
    element:  <AdminProfile />,
    requiredPlan: ""
  },
  {
    path: "dishes",
    element:  <AdminDishes />,
    requiredPlan: "Premium"
  },
  {
    path: "subscriptions",
    element: <AdminSubscription />,
    requiredPlan: ""
  },
  {
    path: "*",
    element: <PageNotFound />
  }
] 

const PurchasePlan=({requiredPlan})=>{
  
  return(
      <div className="flex flex-col items-center justify-center h-full">
          <p className="text-xl inline-block "> 
              This Page is for<Link to="/subscriptions" className="text-red-500 underline">{requiredPlan}</Link>users only 
          </p>
          <Link to="/subscriptions" className="text-red-500 inline-block  text-xl font-semibold underline">Purchase Now</Link>
      </div>
  )
}

const PurchasedPlans=()=>{
  const {user} = useAuth();
  const userPlan = useMemo(()=>user?.company_plan,[user])
  const plans=['','Premium','Elite'];
  const isPurchased =(requiredPlan)=> (plans.indexOf(userPlan) >= plans.indexOf(requiredPlan)) || (!userPlan && !requiredPlan);

  return AdminRoutesConfig.map(({path,index=false,requiredPlan,element})=> {

    return {
      path,
      index,
      element: isPurchased(requiredPlan) ? element : <PurchasePlan requiredPlan={requiredPlan}/>
    }

  })

}

export default PurchasedPlans;