import React, { Fragment, useState,useRef, useEffect, useCallback } from 'react'
import ProfileAvatar from "@images/profile_avatar.png";
import { Loader, SingleFileUpload } from "@components/UtilityComponents";
import Whatsapp from "@images/whatsapp.png";
import axiosInstance from 'apis/config';
import { useAuth } from 'features/contexts/AuthContext';
import { Toaster } from '@constants/appConstants';
import { useNavigate } from 'react-router-dom';
import { PasswdEye } from '@images/sidebarIcons';

const AdminProfile = () => {

  const [userFields,setUserFields] = useState({});
  const [companyFields,setCompanyFields] = useState({});
  const [passwordFields,setPasswordFields] = useState({});
  const [loading,setLoading] = useState({company:false,user:false,password:false})

  const [userPhoto,setUserPhoto] = useState();
  const [companyLogo,setCompanyLogo] = useState();
  const userPhotoRef = useRef(null);
  const companyLogoRef = useRef(null);
  
  const {user,login,logout} = useAuth();
  const navigate = useNavigate()
  const getUserAndCompanyDetails=useCallback(async()=>{
    axiosInstance.get("user/"+user.user_id)
                .then((response)=>{
                  let {user,company} = response;
                  setUserFields(user); 
                  setCompanyFields(company);
                  setCompanyLogo(company.Logo);
                  setUserPhoto(user.photo)
                 })
                .catch((err)=>Toaster("error",err))
  },[user?.user_id]);

  useEffect(()=>{
    getUserAndCompanyDetails();
  },[getUserAndCompanyDetails]);

  const handleUserChange=(e)=>{
    let {value,name} = e.target;
    if(name==="phone_number"){
      value = value?.replace(/\D/g,"")?.slice(0,10)
    }
    setUserFields((prev)=>({...prev,[name]:value}))
  };

  const handleCompanyChange=(e)=>{
    let {value,name} = e.target;
    if(name==="Mobile_Number" || name==="Whatsapp_bot"){
      value = value?.replace(/\D/g,"").slice(0,10)
    }
    setCompanyFields((prev)=>({...prev,[name]:value}))
  }

  const handlePasswordChange=(e)=>{
    let {value,name} = e.target;
    setPasswordFields((prev)=>({...prev,[name]:value}))
  }

  const updateUserDetails=async(e)=>{
    setLoading((prev)=>({...prev,user:true}))
    e.preventDefault();
    const formData = new FormData()
    if(userFields.username.trim().length <= 2){
      Toaster("error","Enter valid Name");
      return;
    }
    if(! (/^\d{10}$/).test(userFields.phone_number?.replace("91",""))){
      Toaster("error","Enter valid phone number");
      return;
    }

    if(! (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(userFields.email))){
      Toaster("error","Enter valid Email");
      return;
    }
    formData.append("user_id",user?.user_id)
    formData.append("username",userFields.username)
    formData.append("phone_number","91"+userFields.phone_number?.replace("91",""))
    formData.append("email",userFields.email)
    if(userPhoto)
      formData.append("photo",userPhoto)
    
    if(userFields?.photo && userPhoto !== userFields?.photo)
      formData.append("oldImage",userFields?.photo)
      
    await axiosInstance.put("user/",formData,{
                headers:{
                  'Content-Type': "multipart/form-data"
              }
            })
            .then((resp)=>{
              if(resp.message==="success"){
                login(resp.token)
                Toaster("success","User Updated Successfully")
                getUserAndCompanyDetails()
              }else{
                console.log(resp);
              }
            })
            .catch((err)=>Toaster("error",err))
      setLoading((prev)=>({...prev,user:false}))
  }

  const updateCompanyDetails=async(e)=>{
    setLoading((prev)=>({...prev,company:true}))
    e.preventDefault()
    if(companyFields.Company_Name?.trim().length <= 2){
      Toaster("error","Enter a valid Company Name");
      return;
    }
    if(! (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(companyFields.Email_Id)) ){
      Toaster("error","Enter valid Company Email")
      return;
    }
    if( !(/^\d{10}$/).test(companyFields.Mobile_Number?.replace("91","")) ){
      Toaster("error","Enter a valid Company Contact no")
      return;
    }

    if( companyFields.Whatsapp_bot && !(/^\d{10}$/).test(companyFields.Whatsapp_bot?.replace("91","")) ){
      Toaster("error","Enter a valid Whatsapp bot number")
      return;
    }

    if( companyFields.Welcome_Text && companyFields?.Welcome_Text?.trim().length <=2  ){
      Toaster("error","Enter valid Welcome Text")
      return;
    }
    if(  companyFields?.Address?.trim().length <=2  ){
      Toaster("error","Enter valid Address")
      return;
    }
    if(  companyFields?.State?.trim().length <=2  ){
      Toaster("error","Enter valid State")
      return;
    }
    
    if(  companyFields?.City?.trim().length <=2  ){
      Toaster("error","Enter valid City")
      return;
    }

    const formData = new FormData()
    formData.append("Welcome_Text",companyFields.Welcome_Text)
    if(companyFields.Whatsapp_bot)
    formData.append("Whatsapp_bot","91"+companyFields.Whatsapp_bot?.replace("91",""))
    formData.append("address",companyFields.Address)
    formData.append("cEmail",companyFields.Email_Id)
    formData.append("cName",companyFields.Company_Name)
    formData.append("cPhoneNumber","91"+companyFields.Mobile_Number?.replace("91",""))
    if(user?.company_plan)
    formData.append("current_plan",user?.company_plan)
    formData.append("state",companyFields.State)
    formData.append("city",companyFields.City)
    if(companyLogo)
      formData.append("Logo",companyLogo)
    
    // old logo if exists
    if(companyFields?.Logo && companyLogo !== companyFields?.Logo)
      formData.append("oldImage",companyFields?.Logo)
    
    await axiosInstance.put("user/company",formData,{
          headers: {
            'Content-Type': "multipart/form-data"
          }
        })
        .then(async(resp)=>{
          if(resp.message==="success"){
            Toaster("success","Company Updated Successfully")
            await getUserAndCompanyDetails()
          }else{
            console.log(resp);
          }
        })
        .catch((err)=>Toaster("error",err))
        setLoading((prev)=>({...prev,company:false}))
  }

  const resetPassword=async()=>{
      if( Object.values(passwordFields).some((el)=>el.trim().length <= 5) ){
        Toaster("error","Password Fields should not be empty")
        return
      } 

      if(passwordFields.new_password.trim() !== passwordFields.confirm_password.trim()){
        Toaster("error","New Password and Confirm Password do not match")
        return;
      }

      axiosInstance.post("user/reset-password",{email:user.Email,...passwordFields})
                  .then(()=>{Toaster("success","Password Resetted Sucessfully"); setPasswordFields({})})
                  .catch(()=>Toaster("error","Old Password is Invalid"))
  }

  return (
    <Fragment>
       <div className="flex justify-between w-[90%] items-center mx-auto mt-7">
            <p className="text-2xl font-bold place-content-start">Profile</p>
        </div>
        <div className="w-[90%] grid grid-flow-row grid-cols-12 mx-auto">
            <div className="col-span-8 m-2 ">
                <p className="text-xl font-medium">Company Section</p>
                <div className="bg-white shadow-lg rounded-3xl p-[10px] flex flex-col justify-between ">
                    <SingleFileUpload image={companyLogo || ProfileAvatar} imageClass="rounded-md m-3  h-40"  fileInputRef={companyLogoRef}
                        handleChange={setCompanyLogo} handleReset={()=>setCompanyLogo()}
                      />

                      {/*company name email contact*/}
                    <input type="text" placeholder="Company Name" name="Company_Name" 
                            value={companyFields.Company_Name} onChange={handleCompanyChange}
                            className="block outline-none m-2  shadow-md border border-gray-400 rounded-3xl p-3"/>
                    <div className='flex'>
                      <input type="email" placeholder="Company e-mail" name="Email_Id"
                              value={companyFields.Email_Id} onChange={handleCompanyChange}
                              className='w-[50%]  outline-none m-2  shadow-md border border-gray-400 rounded-3xl p-3'/>
                      <input type="tel" inputMode='numeric' placeholder="Company number" name="Mobile_Number"
                            value={companyFields.Mobile_Number?.replace("91","")} onChange={handleCompanyChange}
                            className='w-[50%]  outline-none m-2  shadow-md border border-gray-400 rounded-3xl p-3'/>
                    </div>
                    {/*welcome text and whatsapp bot*/}
                    <div className="flex shadow-md m-2 border border-gray-400 rounded-3xl">
                        <img src={Whatsapp} alt="whatsapp" className='size-10 overflow-clip rounded-3xl border border-gray-400 w-[2.875rem] h-[2.875rem]'/>
                      <input type="tel" placeholder='Whatsapp bot number' inputMode='numeric'  name="Whatsapp_bot"
                            value={companyFields.Whatsapp_bot?.replace("91","")} onChange={handleCompanyChange}
                            className="w-[90%] outline-none py-3 px-4 block border-gray-200 shadow-sm focus:z-10" />
                    </div>
                    <input type='text' placeholder='Welcome Text' name="Welcome_Text"
                            value={companyFields.Welcome_Text} onChange={handleCompanyChange}
                            className='outline-none m-2  shadow-md border border-gray-400 rounded-3xl p-3'/>
                    
                    {/*Address Details*/}
                    <textarea rows={5} placeholder='Address' name="Address"
                      value={companyFields.Address} onChange={handleCompanyChange}
                      className='resize-none  outline-none m-2  shadow-md border border-gray-400 rounded-3xl p-3'/>
                    <div className='flex'>
                      <input type='text' placeholder='State' name="State"
                            value={companyFields.State} onChange={handleCompanyChange}
                            className='w-[50%]  outline-none m-2  shadow-md border border-gray-400 rounded-3xl p-3'/>
                      <input type='text' placeholder='City' name="City"
                            value={companyFields.City} onChange={handleCompanyChange}
                            className='w-[50%]  outline-none m-2  shadow-md border border-gray-400 rounded-3xl p-3'/>
                    </div>

                    <button className="bg-[#FFC092] shadow-xl rounded-2xl px-6 py-1 mx-auto text-xl font-medium m-4" 
                     disabled={loading.company}
                    onClick={updateCompanyDetails}>
                      {loading.company ? <Loader /> : "Save"}
                    </button>
                  <div>

                  </div>
                </div>
            </div>
            <div className="col-span-4 m-2 p-3 ">
                <p className="text-xl font-medium">Personal Section</p>
                <div className="bg-white shadow-lg rounded-3xl p-[10px] flex flex-col justify-between">
                  <SingleFileUpload image={userPhoto || ProfileAvatar} imageClass="rounded-full m-3 w-16 h-16" fileInputRef={userPhotoRef}
                      handleChange={setUserPhoto} handleReset={()=>setUserPhoto()}
                    />
                  <input type="text" value={userFields.username} name="username" placeholder="Full Name" onChange={handleUserChange}
                      className="outline-none block m-2 shadow-md border border-gray-400 rounded-3xl p-3"/>
                  <input type="email" placeholder="Your e-mail" value={userFields.email} name="email" inputMode='email' onChange={handleUserChange}
                        className="outline-none block m-2 shadow-md border border-gray-400 rounded-3xl p-3"/>
                  <input type="tel" inputMode='numeric' placeholder="Your mobile number" 
                        value={userFields.phone_number?.replace("91","")} name="phone_number" onChange={handleUserChange}
                        className=" outline-none block m-2 shadow-md border border-gray-400 rounded-3xl p-3"/>
                  <button className="bg-[#FFC092] shadow-xl px-4 py-1 mx-auto my-2 w-fit rounded-2xl text-lg font-medium"
                  disabled={loading.user}
                    onClick={updateUserDetails}>
                    {loading.user ? <Loader /> : "Save"}
                  </button>
                </div>
                <p className="text-xl font-medium m-2">Reset Password Section</p>
                <div className="bg-white shadow-lg rounded-3xl p-[10px] flex flex-col justify-between m-1">
                  <div className='relative mx-4'>
                    <input type="password" id="hs-toggle-password-old" placeholder="Old Password" name="old_password"
                          value={passwordFields.old_password} onChange={handlePasswordChange}
                          className="outline-none w-full block m-2 shadow-md border border-gray-400 rounded-3xl p-3"/>
                    <button type="button" data-hs-toggle-password='{
                          "target": "#hs-toggle-password-old"
                      }' className="absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-400 rounded-e-md focus:outline-none focus:text-[#FFC092]">
                      <PasswdEye className="shrink-0 size-3.5"/>
                    </button>
                  </div>
                  <div className='text-sm underline mx-8 cursor-pointer'
                    onClick={()=>{sessionStorage.removeItem("token"); logout(); navigate("/forgot-password")}}>
                      Forgot password?
                    </div>
                    <div className='relative mx-4'>
                      <input type="password" id="hs-toggle-password-new" placeholder="New Password" name="new_password"
                          value={passwordFields.new_password} onChange={handlePasswordChange}
                          className="outline-none w-full block m-2 shadow-md border border-gray-400 rounded-3xl p-3"/>
                      <button type="button" data-hs-toggle-password='{
                          "target": "#hs-toggle-password-new"
                      }' className="absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-400 rounded-e-md focus:outline-none focus:text-[#FFC092]">
                      <PasswdEye className="shrink-0 size-3.5"/>
                      </button>
                    </div>
                  <div className='relative mx-4'>

                    <input type="password" id="hs-toggle-password-confirm" placeholder="Confirm Password" name="confirm_password"
                      value={passwordFields.confirm_password} onChange={handlePasswordChange}
                      className="outline-none w-full block m-2 shadow-md border border-gray-400 rounded-3xl p-3"/>
                      <button type="button" data-hs-toggle-password='{
                            "target": "#hs-toggle-password-confirm"
                        }' className="absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-400 rounded-e-md focus:outline-none focus:text-[#FFC092]">
                        <PasswdEye className="shrink-0 size-3.5"/>
                        </button>
                    </div>

                  <button disabled={loading.password} onClick={resetPassword} className="bg-[#FFC092] shadow-xl px-4 py-1 mx-auto my-2 w-fit rounded-2xl text-lg font-medium">
                  {loading.password ? <Loader /> : "Update Password"}
                  </button>
                </div>
            </div>
        </div>
    </Fragment>
  )
}

export default AdminProfile