import React, { Fragment,useState,useCallback,useRef,useEffect,useReducer } from 'react'
import { DownCollapse,UpCollapse,PlusIcon,CancelSub,DeleteIcon } from '@images/sidebarIcons';
import {ScrollableModal,SelectReact,ToolTip} from "@components/UtilityComponents";
import axiosInstance from 'apis/config';
import { Toaster } from '@constants/appConstants';

const DishesUnderMenu=({currentDishes=[],setCurrentDishes,addOrEditState})=>{
  
  
  const [newDish,setNewDish] = useState("");
  const [dishesOption,setDishesOption] = useState([]);
  const [optionsParams,setOptionsParams] = useState({
     Limit: 15, Offset: 0, Menu_Name: "" 
  });

  const onInputChange=useCallback((value)=>{
    if(value.length > 3 || value.trim()==="")
      setOptionsParams((prev)=>(
        {...prev,Offset:0, Menu_Name: value.trim()}
      ));
  },[]);

  const getDishesForOptions=useCallback((params)=>{

    axiosInstance.get("/menus/get_all_dishes",{
      params: {...params }
    })
    .then((data)=>{
        setDishesOption((prev)=>params.Offset > 0 ? [...prev,...data] : data)
    })
  },[])

  useEffect(()=>{
    if(addOrEditState)
    getDishesForOptions(optionsParams)
  },[addOrEditState,optionsParams,getDishesForOptions]);
  return(
    <Fragment>
      <div className="grid grid-flow-row grid-cols-4 my-4">
      { currentDishes.length === 0 ?
        <div className='text-center mx-auto col-span-4'>No Dishes</div> :
        currentDishes.map((dish,subIndex)=>{
          return(
            <span key={dish.Menu_Id} className="flex rounded-full bg-[#FFF1DB] p-3 m-1 relative">
            <span className=" text-center inline-flex text-wrap break-words break-all w-fit h-fit justify-center align-middle text-sm items-center m-auto content-center">
              {dish.Menu_Name}
            </span>
             <CancelSub className={`absolute ${addOrEditState ? "inline" : "hidden"} right-0 cursor-pointer`}
                onClick={(e)=> {e.stopPropagation(); setCurrentDishes({type: "delete",deleteIndex: subIndex}) }} />
            </span>
          )
        })
      }
      </div>
      <div className={`max-w-sm space-y-3 ${addOrEditState ? "block" : "hidden"} `}>
        <div className='flex'>

           <SelectReact
            label="Select a Dish"
            options={dishesOption}
            value={newDish}
            onLoadMore={()=>{
                setOptionsParams((prev)=>(
                  { ...prev,Offset: (prev.Offset+prev.Limit) }
                ))
              }
            }
            onChange={setNewDish}
            onInputChange={onInputChange}
            isLoading={false}
            getOptionLabel={(opt)=>opt.Menu_Name}
            getOptionValue={(opt)=>opt.Menu_Id}
            hasMore={true}
          />
          <div className=" p-2 shadow-md shadow-gray-500   border-[1px] border-gray-600 rounded-2xl 
                            bg-[#FFC092] inset-y-0 end-0
                            inline-flex items-center cursor-pointer z-20 m-2" 
                             onClick={()=>{
                              if(typeof newDish === "object")
                              setCurrentDishes({type : "add", data: newDish}); 
                              setNewDish("") 
                            }}> 
              Add <PlusIcon className="rounded-md size-3 " />
            </div>
        </div>
      </div>
    </Fragment>
  )
}


const SingleMenu=({value,index,accordionElementRefs,refetchFixed})=>{
  const handleDishes = (state,action)=>{ 
    switch (action.type) {
      case "add":
            return [...state,action.data];
      case "delete":
            return state.filter((elem,index)=>index!==action.deleteIndex);
      case "all":
            return action.data;
      default:
          return value.dishes;
    }
  };
 
  const [currentDishes,setCurrentDishes] = useReducer(handleDishes,value.dishes || []);
  const [fixedMenuName,setFixedMenuName] = useState(value.Fixed_menu_name || "");
  const [addOrEditState,setAddOrEditState] = useState(false);
  
  useEffect(()=>{
    setAddOrEditState(value.open)
    if(currentDishes.length===0 && value.dishes){
      setCurrentDishes({type:"all",data:value.dishes});
    }
  },[currentDishes.length,value.dishes,value.open]);
  
  const handleSaveFixedMenu=(e)=>{
    e.stopPropagation();e.preventDefault();
    if(addOrEditState){
      if(fixedMenuName?.trim() && currentDishes.length){
        let jsonBody ={
          fixedMenuId: value.Fixed_menu_id,
          fixedMenuName,
          dishes: currentDishes.map((menu)=>menu.Menu_Id).join()
        } 

        axiosInstance.put("menus/fixed-menu",jsonBody)
                      .then((resp)=>{
                        if(resp.fixedMenuName){
                          Toaster("success","Successfully Updated the Fixed Menu")
                        }else{
                          Toaster("error",resp)
                        }
                      })
                      .catch((err)=>{
                        Toaster("error",err)
                      })
        setAddOrEditState(!addOrEditState)
        refetchFixed()
      }else{
        Toaster("error","Fixed Menu Name cannot be empty");
        Toaster("error","Add atleast one dish in fixed menu")
      }
    }else{
      setAddOrEditState(true)
    }
  };

  const handleDeleteFixedMenu=(e,value)=>{
    let confirmation = window.confirm(`Are you sure you want to delete ${value.Fixed_menu_name}`)
    if(value.Fixed_menu_id && confirmation){
        axiosInstance.delete("menus/fixed-menu",{
          params: {
            delete_fixed_id: value.Fixed_menu_id
          }
        })
        .then((resp)=>{
          if(resp.message.includes("success"))
          {Toaster("success",resp.message);refetchFixed()}
        else
          Toaster("error",resp.message);
        })
        .catch((err)=>Toaster("error",err))
    } else{
      refetchFixed();
    } 
  }

  return(
        <Fragment>
          
          <div className="  h-fit p-4 flex justify-between items-center w-full shadow-2xl bg-[#FFC092] rounded-2xl" onClick={(e)=>e.stopPropagation()}>
          <input value={fixedMenuName} onChange={(e)=>setFixedMenuName(e.target.value)} disabled={!addOrEditState}
                placeholder="Fixed menu name" autoFocus={true} onClick={(e)=>e.preventDefault() && e.currentTarget.stopPropagation()}
                className={`py-3 px-4  inline rounded-full outline-none w-[50%]
                          ${ addOrEditState ? " shadow-inner border-[1px] focus:border-gray-600 focus:ring-gray-600 border-gray-600 " 
                            :  " bg-transparent disabled:pointer-events-none"}
                          `}
              />
              {value.open && 
              <span className='group cursor-pointer'>
              <ToolTip cssClass={"-translate-y-full"}>Delete Fixed Menu</ToolTip>
              <DeleteIcon className="size-6 group" onClick={(e)=>handleDeleteFixedMenu(e,value)}/>
              </span>}
          <button  
            className="w-[50%]" tabIndex={-1}
            aria-expanded="true" aria-controls={`hs-acc-${index}`}>
              <span className="flex ml-auto items-center place-content-end">
                <span className="flex flex-col">
                  <div className={`text-[1rem] mx-2 underline ${addOrEditState? "inline" : "hidden"} `}
                  onClick={(e)=>{
                    e.stopPropagation();
                    e.preventDefault();
                    setAddOrEditState(false) ;
                    setFixedMenuName(value.Fixed_menu_name);
                    setCurrentDishes({type: "all",data: value.dishes || []});
                    }  }>
                    Cancel Editing
                  </div>
                  <div className={`text-[1rem] mx-2 underline ${value.open ? "inline" : "hidden"}`}
                    onClick={handleSaveFixedMenu}
                  >
                    {addOrEditState ? "Save Menu Details" : "Edit Menu Details"}
                  </div>
                </span>
                <span ref={(el)=>accordionElementRefs.current[index]=el} className="hs-accordion-toggle group cursor-pointer">
                  <ToolTip>Toggle Fixed Menu</ToolTip>
                  { 
                   value.open ?
                   <UpCollapse className={`size-[2.5rem!important] block`} style={{display: "block" }}/>
                   :
                   <DownCollapse className={`size-[2.5rem!important]`} />
                  }
                </span>
              </span>
          </button>
          </div>
          <div id={`hs-acc-${index}`} 
          className={`hs-accordion-content ${value.open ? "block" : "hidden"} w-[95%]
                      rounded-b-3xl transition-[height] duration-300 py-4 px-6 mx-3 bg-white`}
              aria-labelledby={`hs-id-${index}`} onClick={(e)=>e.stopPropagation()}>

                <DishesUnderMenu currentDishes={currentDishes} 
                    setCurrentDishes={setCurrentDishes} addOrEditState={addOrEditState}/>
            
          </div>
        </Fragment>
  )
  }

const AddNewModal=({onSave,setModalState})=>{
  const handleDishes = (state,action)=>{ 
    switch (action.type) {
      case "add":
            return [...state,action.data];
      case "delete":
            return state.filter((elem,index)=>index!==action.deleteIndex);
      case "all":
            return action.data;
      default:
          return [];
    }
  };
  
  const [fixedMenuName,setFixedMenuName] = useState("");

  const [newDish,setNewDish] = useState("");
  const [dishesOption,setDishesOption] = useState([]);
 
  const [selectedDishes,setSelectedDishes] = useReducer(handleDishes,[]);
  const [optionsParams,setOptionsParams] = useState({
     Limit: 15, Offset: 0, Menu_Name: "" 
  });

  const onInputChange=useCallback((value)=>{
    if(value.length > 3 || value.trim()==="")
      setOptionsParams((prev)=>(
        {...prev,Offset:0, Menu_Name: value.trim()}
      ));
  },[]);



  const getDishesForOptions=useCallback((params)=>{

    axiosInstance.get("/menus/get_all_dishes",{
      params: {...params }
    })
    .then((data)=>{
        setDishesOption((prev)=>params.Offset > 0 ? [...prev,...data] : data)
    })
    .catch((err)=>Toaster("error",err))
  },[])

  useEffect(()=>{
    getDishesForOptions(optionsParams)
  },[optionsParams,getDishesForOptions]);
  
  const handleInsertSave=()=>{
    
    if(fixedMenuName.trim().length === 0){
      Toaster("error","Fixed Menu Name Cannot be empty");
      return;
    }

    if(selectedDishes.length < 1){
      Toaster("error","Atleast one Dish should be selected")
      return;
    }

    axiosInstance.post("menus/fixed-menu",{
      fixedMenuName,
      dishes: selectedDishes.map((menu)=>menu.Menu_Id).join()
    })
    .then((resp)=>{
      if(resp.fixedMenuName){
        Toaster("success","Successfully Inserted the Fixed Menu")
        onSave();
        setModalState(false)
      }else{
        Toaster("error",resp)
      }
    })
    .catch((err)=>{
      Toaster("error",err)
    })
    
  }

  return(
      <div className="p-12 m-5 w-[100%]">
        <input value={fixedMenuName} onChange={(e)=>setFixedMenuName(e.target.value)} 
                  placeholder="Fixed Menu name" autoFocus={true}
                  className={`py-3 px-4 w-[600px]  inline rounded-full outline-none 
                            shadow-inner border-[1px] focus:border-gray-600 focus:ring-gray-600 border-gray-600 " `}
                />
        <div className="grid grid-flow-row grid-cols-4 my-4">
          { 
          selectedDishes.length === 0 ?
            <div className='text-center mx-auto col-span-4'>No Dishes</div> :
            selectedDishes.map((dish,subIndex)=>{
              return(
                <span key={dish.Menu_Id} className="flex rounded-full bg-[#FFF1DB] p-3 m-1 relative">
                <span className=" text-center inline-flex text-wrap break-words break-all w-fit h-fit justify-center align-middle text-sm items-center m-auto content-center">
                  {dish.Menu_Name}
                </span>
                <CancelSub className={`absolute inline right-0 cursor-pointer`}
                    onClick={(e)=> {e.stopPropagation(); setSelectedDishes({type: "delete",deleteIndex: subIndex}) }} />
                </span>
              )
            })
            }
        </div>
        <div className={`  block `}>
          <div className='flex'>

            <SelectReact
              label="Select a Dish"
              options={dishesOption}
              value={newDish}
              onLoadMore={()=>{
                  setOptionsParams((prev)=>(
                    { ...prev,Offset: (prev.Offset+prev.Limit) }
                  ))
                }
              }
              onChange={setNewDish}
              onInputChange={onInputChange}
              isLoading={false}
              getOptionLabel={(opt)=>opt.Menu_Name}
              getOptionValue={(opt)=>opt.Menu_Id}
              hasMore={true}
            />
            <div className=" p-2 shadow-md shadow-gray-500   border-[1px] border-gray-600 rounded-2xl 
                              bg-[#FFC092] inset-y-0 end-0
                              inline-flex items-center cursor-pointer z-20 m-2" 
                              onClick={()=>{
                                if(typeof newDish === "object")
                                setSelectedDishes({type : "add", data: newDish}); 
                                setNewDish("") 
                              }}> 
                Add <PlusIcon className="rounded-md size-3 " />
              </div>
          </div>
        </div>
        <div className=" p-2 shadow-md shadow-gray-500   border-[1px] border-gray-600 rounded-2xl 
                              bg-[#FFC092] inset-y-0 end-0 mx-auto content-center
                              inline-flex items-center cursor-pointer z-20 m-2"
          onClick={handleInsertSave}
          >
            Save Menu
        </div>
      </div>
  
  )
}

const AdminFixedMenu = () => {
  
  const [fixedMenus,setFixedMenus] = useState([]);

  const accordionElementRefs = useRef([]);

  const addDishesInMenu=(fixedMenusCopy,index)=>{      

      axiosInstance.get(`/menus/get_fixed_menu_dishes/${fixedMenusCopy[index].Fixed_menu_id}`)
                    .then((resp)=>{
                      fixedMenusCopy[index].dishes = resp;
                      setFixedMenus(fixedMenusCopy);
                    })
                    .catch((err)=>Toaster("error",err))

  };

  const updateFixedMenuDetail=useCallback((index)=>{
    let fixedMenusCopy = fixedMenus.slice();
    const toggleState = !fixedMenus[index].open;
    //reset toggle
    fixedMenusCopy = fixedMenusCopy.map((accItem)=>{accItem.open=false;return accItem});
    fixedMenusCopy[index].open = toggleState;
    
    if(!fixedMenusCopy[index].dishes){
      addDishesInMenu(fixedMenusCopy,index);
    }else{
      setFixedMenus(fixedMenusCopy)
    }

  },[fixedMenus]);
  
  const handleOpenState=useCallback((e,index)=>{
      e.preventDefault();
      e.stopPropagation();
      updateFixedMenuDetail(index) 
    
  },[updateFixedMenuDetail]);

  useEffect(()=>{
    const cleanUps = accordionElementRefs.current.map((element,index)=>{
      if(element){
        const boundHandler=(e)=>handleOpenState(e,index);
        element.addEventListener("click",boundHandler);

        return ()=>{  
          element.removeEventListener("click",boundHandler);
        }
      }
      return undefined;
    });

    return ()=>{
      cleanUps.forEach((cleanup)=>{
        if(cleanup) cleanup();
      })
    }
  },[handleOpenState]);
  
  const getAllFixedMenus=()=>{
    axiosInstance.get("/menus/all_fixed_menus")
                .then(setFixedMenus)
                .catch((err)=>Toaster("error",err))
  }

  useEffect(()=>{
    getAllFixedMenus();
  },[]);


  const [modalState,setModalState] = useState(false)

  return (
    <Fragment>
        <div className="flex justify-between w-[80%] items-center mx-auto mt-7">
            <p className="text-2xl font-bold">Fixed Menus</p>
            <button className="cursor-pointer rounded-full font-semibold px-6 py-3 m-1.5 shadow-xl bg-[#FFC092]" onClick={()=>setModalState(true)}>
              Add Fixed Menu
            </button>
            
        </div>
        <ScrollableModal title={"Add New Fixed Menu"} 
          content={<AddNewModal onSave={getAllFixedMenus} setModalState={setModalState}/>}  
          modalState={modalState} onClose={()=>setModalState(!modalState)}/>
        <div className="hs-accordion-group w-[80%] my-4 container grid grid-flow-row grid-cols-1 mx-auto lg:grid-cols-2  gap-x-8">
              {
                fixedMenus?.map((value,index)=>{
                  return(
                    <Fragment key={value.Fixed_menu_name}>
                      <div className={ `hs-accordion col-span-1 rounded-2xl  flex-1 my-4 max-h-fit p-1 ${value.open ? "active" : ""}`} id={`hs-id-${index}`} >
                          <div className=" h-fit  gap-x-1 mx-auto
                            font-semibold text-start text-gray-800"
                            aria-expanded="true" aria-controls={`hs-acc-${index}`}
                            >
                            <SingleMenu value={value} index={index} accordionElementRefs={accordionElementRefs} refetchFixed={getAllFixedMenus}/>
                        </div>
                      </div>
                    </Fragment>
                  )
                })
              }
        </div>
              {fixedMenus.length===0 && <div className='text-center m-auto my-[10%] font-medium'>No Fixed Menu to show. Insert New fixed Menu</div>}
    </Fragment>
  )
}

export default AdminFixedMenu