import PageNotFound from "@pages/PageNotFound";
import SADashboard from "@pages/SuperAdminPages/SADashboard";
import SAPlans from "@pages/SuperAdminPages/SAPlans";

const SuperAdminRouteConfig = [
      { 
        path: "/",
        index: true,
        element:  <SADashboard />,
      },
      {
        path: "/plans-list",
        element:  <SAPlans />,
      },
      {
        path: "*",
        element: <PageNotFound />
      }
]

export default SuperAdminRouteConfig;