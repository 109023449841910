import Footer from '@components/Footer'
import Header from '@components/Header'
import React, { Fragment } from 'react'

const PrivacyPolicy = () => {
  return (
    <Fragment>
    <style jsx="true">
        {
            `
                ol {
                    list-style: numeric;
                }
                ul {
                    list-style: disc;
                }
                ol li {
                    margin: 3rem 0;
                }

                li ul li,li ol li{
                    margin: 0.5rem 0;
                }
                .list-alpha{
                    list-style: lower-alpha
                }
                #privacy-policy{
                    font-size: 21px;
                }
                #privacy-policy a{
                    text-decoration: underline
                }
            `
        }
    </style>
    <Header />
    <main className='relative top-28 pt-10 max-w-max mx-0 min-h-[90vh] mb-32'>
        <section id="privacy-hero" className="min-w-[99vw] mx-1 py-1 relative">
            <div style={{  position: 'relative',zIndex:5,margin: "0 35%" }}>
                <p className='text-5xl text-center font-semibold text-nowrap w-[100%] lg:mx-auto h-[-webkit-fill-available]'>
                    Privacy Policy
                </p>
            </div>
            <p className='text-[21px] mx-auto my-3 w-[75%]'>
                At Catermate AI, a product of NeuroNest AI Pvt Ltd, we prioritize your privacy and are committed to safeguarding the personal and business information you share with us. 
                This Privacy Policy outlines how we collect, use, share, and protect your data when you use our services.
                By using Catermate AI, you consent to the practices described in this Privacy Policy.  
            </p>
        </section>
        <section id="privacy-policy" className="my-14">
            <ol className='w-[70%] mx-auto'>
                <li>
                    <p className='font-semibold'>Information We Collect</p>
                    <div>
                        We collect the following types of information to provide and enhance our services:  
                        <ol className='mx-[5%] list-alpha'>
                            <li>
                                <div className='font-semibold'>Personal Information:</div>    
                                Name, email address, phone number, and company details when you register for our services. Payment information for subscription processing. 
                            </li>
                            <li>
                                <div className='font-semibold'>Business Data:</div>    
                                Menu selections, event details, and other catering-related data input into the platform. 
                            </li>
                            <li>
                                <div className='font-semibold'>Technical Data:</div>
                                Device information, IP address, and usage statistics to improve the platform's functionality.
                            </li>
                            <li>
                                <div className='font-semibold'>Communication Data:</div>
                                Information shared through support or queries, including feedback and user correspondence.
                            </li>
                        </ol>
                    </div>
                </li>
                <li>
                    <div className='font-semibold'>How We Use Your Information</div>
                    <p>We use the information collected for the following purposes: </p>
                    <ol className='mx-[5%]'>
                        <li>To provide and improve the Catermate AI services.</li>
                        <li>To process payments and manage subscriptions.</li>
                        <li>To personalize user experience and generate insights for catering operations,</li>
                        <li>To send notifications, updates, or promotional materials.</li>
                        <li> To ensure compliance with legal obligations.</li>
                    </ol>
                </li>
                <li>
                    <div className='font-semibold'>Sharing of Information</div>
                    <p>We do not sell, rent, or trade your personal or business information. However, we may share your data in the following circumstances: </p>
                    <li><strong>With Service Providers:</strong>To process payments, manage infrastructure, or provide customer support. </li>
                    <li><strong>For Legal Compliance:</strong>If required by law or in response to valid legal processes.</li>
                    <li><strong>Business Transfers:</strong>In case of mergers, acquisitions, or sale of assets.</li>
                </li>
                <li>
                    <div className='font-semibold'> Data Protection</div>
                    <p>
                    We implement industry-standard security measures to protect your information from unauthorized access, loss, or misuse. 
                    These include encryption, access controls, and regular security audits.
                    </p>
                </li>
                <li>
                    <div className='font-semibold'>User Rights</div>
                    <p>You have the following rights regarding your information:</p>
                    <ol className='mx-[5%]'>
                        <li><strong>Access and Correction:</strong> Review and correct your personal information.</li>
                        <li><strong>Data Portability:</strong>Request a copy of your data in a usable format.</li>
                        <li><strong>Deletion:</strong>Request deletion of your data, subject to legal or operational obligations.</li>
                        <li><strong>Opt-Out:</strong>Unsubscribe from promotional communications at any time.</li>
                    </ol>
                </li>
                <li>
                    <div className='font-semibold'>Retention of Data</div>
                    <div>We retain your information for as long as necessary to fulfill the purposes outlined in this policy or comply with legal requirements.</div>

                </li>
                <li>
                    <div className='font-semibold'>Third-Party Services</div>
                    <div>Catermate AI may integrate with third-party tools or services. Please note that these platforms have their own privacy policies, and we are not responsible for their practices. </div>
                </li>
                <li>
                    <div className='font-semibold'>Changes to this Privacy Policy</div>
                    <div>We may update this policy periodically. Significant changes will be communicated via email or platform notifications.</div>
                </li>
                <li>
                    <div className='font-semibold'>Contact us:</div>
                    For questions, concerns, or requests regarding this Privacy Policy, please contact us: 
                    <div className='font-semibold'>Catermate AI</div>
                    <ul className="mx-[5%]">
                        <li>Email: <a href="mailto:info@catermateai.com" target="_blank" rel="noreferrer">info@catermateai.com</a></li>
                        <li>Contact: <a href="tel:+919655445481">+91 96554 45481</a></li>
                    </ul>
                    <div className='font-semibold'>Neuronest AI</div>
                    <ul className="mx-[5%]">
                        <li>Email: <a href="mailto:info@neuronestai.in" target="_blank" rel="noreferrer">info@neuronestai.com</a></li>
                        <li>Contact: <a href="tel:+919655442477">+91 96554 42477</a></li>
                        <li>Website: <a href="https://neuronestai.com" target="_blank" rel='noreferrer'>https://neuronestai.com</a></li>
                    </ul>
                </li>
            </ol>
        </section>
    </main>
    <Footer />
    </Fragment>
  )
}

export default PrivacyPolicy