import Pagination from '@components/Pagination';
import PricingPlans from '@components/PricingPlans'
import { TableCellWithToolTip } from '@components/Table';
import { Toaster,diffbtwDates } from '@constants/appConstants';
import { SubscriptionIcon, TransactionHistory } from '@images/sidebarIcons';
import axiosInstance from 'apis/config';
import { useAuth } from 'features/contexts/AuthContext';
import React, { useCallback, useEffect, useMemo, useState,Fragment } from 'react'

const PlanStatusChip=({status,sub_end})=>{

  const bgColor =  useMemo(() => {
      switch (status) {
          case "active":
                return "bg-green-100 text-green-600"
      
          case "pending":
                return "bg-yellow-500 text-black"
          
          case "halted":
            return "bg-red-600 text-white"
          case "completed":
            return "bg-gray-500 text-white"
          default:
              return "bg-gray-500 text-white"
      }
  }, [status])

  return(
      <Fragment>
          {
              status ? 
              <div>
              <div className={` rounded-full uppercase px-3 py-2.5 text-center font-bold  ${bgColor}`}>
                  {status}
              </div>
              {status==="completed" ? 
                <sub className='m-2 bg-red-500 p-2 text-white rounded-full font-bold'>
                  {diffbtwDates(new Date(),sub_end)}
                </sub> : null}
              </div>
              : "-"
          }
      </Fragment>
  )
}


const AdminSubscription = () => {
  let ignorePlans = ["Basic"];
  
  const {user} = useAuth();
  const {sub_id,company_plan,company_id} = useMemo(()=>user||{},[user])
  const columns=[{key:"id",name:"Transaction Id"},{key:"payment_method",name:"Payment Method"},
                  {key:"amount_in_paise",id:"Amount (INR)"},{key:"created_at",name:"Purchased Date"}]
  const [subscriptionDetails,setSubscriptionDetails] = useState({});
  const [transactionHist,setTransactionHist] = useState([]);
  const [currentPage,setCurrentPage] = useState(1);
  const [totalCount,setTotalCount] = useState(0);
  const [pageSize,setPageSize] = useState(10);

  useEffect(()=>{
    axiosInstance.get("payments/transactions",{
        params: {
            Limit: pageSize,
            Offset: ((currentPage-1)*pageSize),
        }
    })
    .then((data)=>{
        if(data[0]?.total_count){
            const totalRows  = data[0].total_count;
            setTransactionHist(data);
            setTotalCount(totalRows)
        }else{
            Toaster("error",data)
        }
    })
    .catch((err)=>Toaster("error",err))
  },[currentPage,pageSize]);

  const getSubscriptions=useCallback(()=>{
    if(sub_id)
      axiosInstance.get("payments/subscriptions",{
        params:{id:sub_id,Limit:1,Offset:0,company_id : company_id}
      })
      .then((resp)=>{
        setSubscriptionDetails(resp[0])
        if(resp[0]?.status ==="completed"){
          let daysRemaining = diffbtwDates(new Date(),resp[0]?.sub_end)
          if(parseInt(daysRemaining.match(/\d+/)[0],10) < 15) //toast if less than 15 days
          Toaster("warn",daysRemaining+ " Plan is about to expire ",{autoClose:false})
        }
      })
      .catch((err)=>Toaster("error",err))
  },[sub_id,company_id])

  useEffect(()=>{
    getSubscriptions()
  },[getSubscriptions])

  function getValidDate(date){
    let dateString = new Date(date)?.toDateString()
    return dateString !== "Invalid Date" ? dateString : "-"
  }
  return (
    <div className='mx-auto'>
      {/*switching tabs*/}
      <div className="border-b border-gray-200 mt-6">
      <style>{`.active .active-icon .st0{fill:#FF6309 !important;}`}</style>
          <nav className="flex gap-x-1"  aria-label="Tabs" role="tablist" aria-orientation="horizontal"> {/*NOSONAR*/}
              <button type="button" 
                  className=" hs-tab-active:font-semibold text-xl hs-tab-active:border-[#FF6309] hs-tab-active:text-[#FF6309] py-4 px-1 inline-flex items-center gap-x-2 border-b-2 border-transparent whitespace-nowrap text-gray-500 hover:text-[#FF6309] focus:outline-none disabled:opacity-50 disabled:pointer-events-none active" 
                  id="tabs-with-underline-item-1" aria-selected="true" data-hs-tab="#tabs-with-underline-1" aria-controls="tabs-with-underline-1" role="tab">
                <SubscriptionIcon className="active-icon size-8"/>Subscription Plans
              </button>
              <button type="button" 
                  className="hs-tab-active:font-semibold text-xl hs-tab-active:border-[#FF6309] hs-tab-active:text-[#FF6309] py-4 px-1 inline-flex items-center gap-x-2 border-b-2 border-transparent whitespace-nowrap text-gray-500 hover:text-[#FF6309] focus:outline-none  disabled:opacity-50 disabled:pointer-events-none" 
                  id="tabs-with-underline-item-2" aria-selected="false" data-hs-tab="#tabs-with-underline-2" aria-controls="tabs-with-underline-2" role="tab">
                <TransactionHistory className="active-icon size-8"/> Transaction
              </button>
          </nav>
      </div>
      {/*tab content*/}
      <div className="container">
        <div id="tabs-with-underline-1" role="tabpanel" aria-labelledby="tabs-with-underline-item-1">
          <PricingPlans subscriptionPage={ignorePlans}/>
        </div>
        <div id="tabs-with-underline-2" className="hidden" role="tabpanel" aria-labelledby="tabs-with-underline-item-2">
          <div className='mt-3'>
            <div className='bg-white rounded-2xl shadow-xl grid grid-cols-10 min-h-fit'>
              <div className='col-span-3 grid grid-cols-2 p-5 text-base'>
                <div className='col-span-2 ps-3 font-medium text-2xl'>Recent Subscription</div>
                <div className='flex flex-col gap-2 text-left font-medium'>
                  <div className='px-3 py-2.5 '>Current Status</div>
                  <div className='px-3 py-2.5 '>Current Plan</div>
                  <div className='px-3 py-2.5 '>Purchase Date</div>
                  <div className='px-3 py-2.5 '>Expiration Date</div>
                </div>
                <div className='flex flex-col gap-2'>
                    <div className='px-3 py-2.5'>
                      <PlanStatusChip status={subscriptionDetails?.status} sub_end={subscriptionDetails?.sub_end}/>
                    </div>
                    <div className={`px-3 py-2.5 rounded-full ${company_plan && " bg-yellow-100 text-center"} text-yellow-600  font-bold`}>{company_plan || "-"}</div>
                    <div className='px-3 py-2.5 text-left font-medium'>{getValidDate(subscriptionDetails?.sub_start)}</div>
                    <div className='px-3 py-2.5 text-left font-medium'>{getValidDate(subscriptionDetails?.sub_end)}</div>
                </div>
              </div>
              <div className='col-span-7'>
              <div className="h-full w-px bg-gray-300 mx-4 inline-block"></div>
                <div className="inline-block w-[95%] rounded-2xl relative z-30 overflow-x-hidden max-h-[80vh] overflow-y-scroll shadow-md">
                  <table className="rounded-2xl table-fixed relative min-w-full">
                        <thead className="rounded-2xl sticky top-0">
                          <tr className='bg-[#FFC092] rounded-2xl'>
                            <th className='font-bold text-lg' colSpan={5}>Transactions</th>
                          </tr>
                          <tr className="bg-[#FFC092] rounded-2xl">
                                {
                                    columns.map((col)=>(
                                        <TableCellWithToolTip key={col.key} 
                                        className="p-4 text-center truncate whitespace-nowrap overflow-hidden max-w-[150px]"
                                        content={col.name} header/>
                                    ))
                                }
                                <th className="p-4 text-center truncate whitespace-nowrap overflow-hidden max-w-[150px]">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                transactionHist.map((row,rowIndex)=>(
                                    <tr key={row.id} className={`p-2 ${rowIndex%2 === 0 && " bg-white "}`}>
                                        {
                                            columns.map((col)=>(
                                              <TableCellWithToolTip key={col.key} 
                                              className="p-4 text-center truncate whitespace-nowrap overflow-hidden max-w-[150px]"
                                              content={ col.key === "amount_in_paise" ? row[col.key]/100 :  row[col.key]} />
                                            ))
                                        }
                                        <td className="p-2 text-center">
                                          <button className="rounded-3xl bg-[#ffc092] px-4 py-2 fonte-semibold shadow-lg">
                                            View Invoice
                                          </button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                        <tfoot>
                          <tr className="bg-white border-t-[1px] border-gray-300 sticky bottom-0">
                            <td className='text-center p-4' colSpan={columns.length+1}>
                                <Pagination
                                    currentPage={currentPage}
                                    totalCount={totalCount}
                                    pageSize={pageSize}
                                    onPageSizeChange={setPageSize}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                            </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminSubscription