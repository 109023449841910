import { ScrollableModal, StdButton } from '@components/UtilityComponents'
import { Toaster } from '@constants/appConstants';
import axiosInstance from 'apis/config';
import React, { Fragment, useEffect, useState } from 'react'

const inputClass='p-1.5 rounded-3xl outline-none border border-gray-400 shadow-2xl disabled:bg-slate-200 disabled:text-black disabled:font-bold'

//   function stringToColorObj(inputString){
//     // const inputString = "from-[#f98889] to-[#a73b3d]";
//     const regex = /from-\[(#\w+)\] to-\[(#\w+)\]/;
//     const matches = inputString.match(regex);

//     if (matches) {
//     const colorObj = {
//         from: matches[1],
//         to: matches[2]
//     };
//     return colorObj;
//     } else {
//     return {from:"#000000",to:"#ffffff"}
//     }
//   }

const PlanModal=({modalContent={},setModalState})=>{
    const [planDetail,setPlanDetail] = useState(modalContent || {status:"active",bg_color: `from-[#000000] to-[#ffffff]` })
    // const [colorChange,setColorChange] = useState(modalContent.bg_color  ? stringToColorObj(modalContent.bg_color) : {from:"#000000",to:"#ffffff"})

    const handleChange=(e)=>{
        let {name,value} = e.target;
        let planCopy = {...planDetail}
        value = ["amount_in_paise","gst_percent"].includes("name") ? value?.replace(/\D/g,"") :value
        if(name==="amount_in_paise"){
            planCopy[name] = value*100 // store amount in paise
        }else{
            planCopy[name] = value
        }
        let amount = (planCopy["amount_in_paise"] || 0)
        planCopy["taxed_amount"] = amount + (amount* (planCopy["gst_percent"] || 0)/100)
        setPlanDetail(planCopy)
    } 
    // useEffect(()=>{
    //     setPlanDetail((prev)=>({
    //             ...prev,
    //             bg_color: `from-[${colorChange.from}] to-[${colorChange.to}]`
    //         }
    //     ))
    //     console.log(colorChange,planDetail.bg_color);
        
    // },[colorChange])

    // const updatePlan=()=>{
    //     const HTTP_METHOD = modalContent.id ? "put" : "post"
    //     axiosInstance[HTTP_METHOD]("admin/plan",planDetail)
    //                     .then((resp)=>{
    //                         console.log("resp",resp)
    //                         setModalState(false)
    //                     })
    //                     .catch((err)=>{
    //                         console.log(err)
    //                     })
    // }

    return(
        <Fragment>
            <style>
                {
                    `
                    #create ul{
                        list-style: disc;
                     }
                `
                }
            </style>
            <div id="create" className='grid grid-cols-2 grid-flow-row w-[80vw]'>
             <div id="dynamic-color" className=' bg-[white] w-full h-full px-32 py-16 grid grid-flow-row grid-cols-1 gap-6'>
                <input className={inputClass} placeholder='Plan Name' name={"plan_name"} 
                        value={planDetail.plan_name} onChange={handleChange} disabled={modalContent.id}/>
                <input className={inputClass} placeholder='Amount in Rs ₹' name={"amount_in_paise"} 
                        value={(planDetail.amount_in_paise || 0)/100} onChange={handleChange} disabled={modalContent.id}/>
                <input className={inputClass} placeholder='GST Tax in %' name={"gst_percent"} 
                        value={planDetail.gst_percent} onChange={handleChange} disabled={modalContent.id}/>
                <input className={inputClass} placeholder='Total Taxed amount' disabled 
                        value={(planDetail.taxed_amount || 0)/100} />
                <textarea placeholder='description' className={inputClass+ "w-[500px] resize-none "} 
                        value={planDetail.description} onChange={handleChange}></textarea>
                <textarea placeholder='comma separated perks' className={inputClass+ " resize-none "} 
                        value={planDetail.perks} onChange={handleChange}></textarea>
                <select value={planDetail.status} onChange={handleChange} name='status'>
                    <option value="active" name='status'>Active</option>
                    <option value="inactive" name='status'>In Active</option>
                </select>
                {/* {
                    ["from","to"].map((type)=>(
                        <input type="color" name={type} value={colorChange[type]} key={type}
                            onChange={(e)=>setColorChange((prev)=>({...prev,[type]:e.target.value}))}/>
                    ))
                }
                 */}
            </div>
            <div key={planDetail.plan_name} className='relative mx-auto max-w-[400px]'>
                <div 
                    className={`   w-[60%] top-8 h-[5rem] z-20 text-center  overflow-visible border-[10px] border-[#FEF1E1] mx-auto 
                        rounded-2xl relative py-2 px-3 mb-1 text-white  bg-gradient-to-b ${planDetail.bg_color}`}>
                    <span className='text-3xl text-center font-bold'>₹{planDetail.amount_in_paise / 100}</span>
                    <sub className='text-[12px] block text-right'>/{planDetail.plan_frequency} {planDetail.gst_percent}%gst</sub>
                </div>
                <div className={`rounded-3xl text-center relative font-bold text-white max-w-[24rem] md:max-h-screen h-auto bg-gradient-to-b ${planDetail.bg_color}  p-6 py-12`}>
                    <p className="text-3xl font-semibold">{planDetail.plan_name}</p>
                    <hr className="h-[2px] w-[80%] mx-auto bg-white"/>
                    <div className="font-medium">Description & Benefits</div>
                    <p className="w-[80%] text-wrap mx-auto">{planDetail.description}</p>
                    <hr className="h-[2px] w-[80%] mx-auto bg-white"/>
                    <ul className="text-start ml-4 mt-6 px-14 w-[115%]">
                        {
                            planDetail.perks?.split(',').map((perk)=>{
                                return(
                                    <li key={perk}>{perk}</li>
                                )
                            })
                        }
                    </ul>
                    <StdButton className={`bg-white text-black rounded-full w-[50%] block p-3 mt-[5%] mx-[25%] relative 
                                    top-[5%] cursor-pointer disabled:cursor-not-allowed disabled:text-white`}>
                        Buy Now
                    </StdButton>
                </div>
            </div>
            </div>
        </Fragment>
    )
}

const SAPlans = () => {

    const [plans,setPlans] = useState([]);
    const [modalState,setModalState] = useState(false);
    const [modalContent,setModalContent] = useState({});

    useEffect(()=>{
        axiosInstance.get("admin/plans-list")
                .then(setPlans)
                .catch((err)=>Toaster("error",err))
    },[])

  return (
    <Fragment>
        <div  className="flex justify-between w-[80%] plans-center mx-auto mt-7 sticky top-0">
        <style>
                {
                    `
                    #subscribe ul{
                        list-style: disc;
                     }
                    `
                }
            </style>
            <p className="text-2xl font-bold place-content-start">Subscription Plans</p>
            <StdButton onClick={()=>setModalState(true)}>Create Plan</StdButton>
        </div> 
        <ScrollableModal modalState={modalState} title={modalContent.id ? "Edit Plan" :'ADD New Plan'} 
            content={<PlanModal modalContent={modalContent} setModalState={setModalState}/>} onClose={()=>{setModalState(false);setModalContent({})}}/>
        <section className='max-h-[80vh] overflow-scroll' id="subscribe">
        <div className='grid grid-cols-2 max-xl:grid-cols-1 max-xl:grid-flow-row p-4 mx-auto   items-stretch justify-items-center content-center relative'>
            {
                plans.map((plan)=>(
                    <div key={plan.plan_name} className='relative'>
                    <div 
                        className={`   w-[60%] top-8 h-[5rem] z-20 text-center  overflow-visible border-[10px] border-[#FEF1E1] mx-auto 
                            rounded-2xl relative py-2 px-3 mb-1 text-white  bg-gradient-to-b ${plan.bg_color}`}>
                        <span className='text-3xl text-center font-bold'>₹{plan.amount_in_paise / 100}</span>
                        <sub className='text-[12px] block text-right'>/{plan.plan_frequency}+{plan.gst_percent}% gst</sub>
                    </div>
                    <div className={`rounded-3xl text-center relative font-bold text-white max-w-[24rem] md:max-h-screen h-auto bg-gradient-to-b ${plan.bg_color}  p-6 py-12`}>
                        <p className="text-3xl font-semibold">{plan.plan_name}</p>
                        <hr className="h-[2px] w-[80%] mx-auto bg-white"/>
                        <div className="font-medium">Description & Benefits</div>
                        <p className="w-[80%] text-wrap mx-auto">{plan.description}</p>
                        <hr className="h-[2px] w-[80%] mx-auto bg-white"/>
                        <ul className="text-start ml-4 mt-6 px-14 w-[115%]">
                            {
                                plan.perks.split(',').map((perk)=>{
                                    return(
                                        <li key={perk}>{perk}</li>
                                    )
                                })
                            }
                        </ul>
                        <StdButton className={"px-2 py-2 text-black"} 
                            onClick={()=>{setModalState(true);setModalContent(plan)}}>
                            Update
                        </StdButton>
                    </div>
                </div>
                ))
            }        
        </div>
        </section>
    </Fragment>
  )
}

export default SAPlans