import React from 'react'
import FAQ_image from "@images/FAQ_image.png";
import { DownCollapse, UpCollapse } from '@images/sidebarIcons';

const FAQContents = [
    {
        title: "How does Catermate help manage clients?",
        content: `<strong>Catermate simplifies client management</strong> via WhatsApp, enabling seamless communication, menu selection, and event updates in one place.`
    },
    {
        title: "Can Catermate send notifications automatically?",
        content: `<strong>Yes, Catermate sends automated WhatsApp notifications </strong> to vendors and clients, keeping everyone informed and prepared for events.`
    },
    {
        title: "What insights are available on the dashboard?",
        content: `<strong>The dashboard provides key metrics </strong> on events, client interactions, and lead generation, helping you make data-driven decisions.`
    }, 
    {
        title: "Is customer support available?",
        content: `<strong>Yes, we offer 24/7 support </strong> to assist with any questions or issues, ensuring a smooth experience with Catermate.`
    }
]


const Accordion=()=>{

    return(
        <div className="hs-accordion-group my-10 max-xl:mx-3">
            {
                FAQContents.map((question,index)=>(
                <div className="hs-accordion my-3" id={`hs-basic-with-title-and-arrow-stretched-heading-${index}`} key={question.title}>
                    <button className="bg-white hs-accordion-toggle hs-accordion-active:text-blue-600 p-3 inline-flex items-center justify-between gap-x-3 w-full font-medium text-start text-gray-800 hover:text-gray-500 rounded-lg disabled:opacity-50 disabled:pointer-events-none" 
                    aria-expanded="true" aria-controls={`hs-basic-with-title-and-arrow-stretched-collapse-${index}`}>
                    {question.title}
                    <DownCollapse />
                    <UpCollapse />
                    </button>
                    <div id={`hs-basic-with-title-and-arrow-stretched-collapse-${index}`} 
                        className="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300 bg-[#F8F8F8] rounded-lg p-4" 
                    aria-labelledby={`hs-basic-with-title-and-arrow-stretched-heading-one`}>
                    <p className="text-gray-800" dangerouslySetInnerHTML={{ __html: question.content }}></p>
                    </div>
                </div>
                ))
            }
        </div>
    )
}

const FAQ = () => {
  return (
        <section id="faq" className="my-7 ">
            <div className="grid grid-flow-col max-xl:grid-flow-row grid-cols-2 max-xl:grid-cols-1">
                <div className="xl:max-w-[43vw] max-xl:hidden">
                    <img src={FAQ_image} alt="faq bot" className="scale-75"/>
                </div>
                <div className="xl:max-w-[43vw] max-xl:w-[100%]">
                    <h1 className="text-[#D51037] text-4xl  font-semibold ">Frequently Asked Questions</h1>
                    <p className="font-medium xl:max-w-[80%] text-[1.1rem]">
                        Get quick answers to the most common questions about Catermate. 
                        From Client Management to complex Ingredient management, explore how Catermate can assist you in your catering journey. 
                        If you don't find what you're looking for, feel free to reach out to our support team!
                    </p>
                    <Accordion />
                </div>
            </div>
        </section>
  )
}

export default FAQ