import React, { Fragment,useEffect,useRef,useState } from 'react'
import ProfileAvatar from "@images/profile_avatar.png";
import {DeleteIcon, EditIcon} from "@images/sidebarIcons";
import axiosInstance, { baseURL } from 'apis/config';
import { isValidUrl, Toaster } from '@constants/appConstants';
import { Loader, ScrollableModal, SelectReact, SingleFileUpload, StdButton, ToolTip } from '@components/UtilityComponents';


const VendorModal=({content,onSave})=>{
    const [vendorFields,setVendorFields] = useState({
            ...content,
            vType:{Vendor_type: content.vendor_type,Vendor_Id:content.Type_id}
        });

    const [vendorTypeOptions,setVendorTypeOptions] = useState([]);
    const [loading,setLoading]=useState(false);

    const photoRef = useRef()

    useEffect(()=>{
        axiosInstance.get("business/vendors/getVendorType",{
            params: {Limit:100,offset:0}
        })
        .then(setVendorTypeOptions)
        .catch((err)=>Toaster("error",err))
    },[]);

    const handleSave=async()=>{
        if(vendorFields.Name.trim() <=2 || !vendorFields.Mobile_Number?.replace("91","") || !vendorFields.vType.Vendor_Id){
            Toaster("error","Please Enter valid name and Number")
            return;
        }
        setLoading(true)
        const HTTP_METHOD = content.id ? "put" : "post";
        const formData = new FormData();
        if(content.id)
        formData.append("id",content.id);

        formData.append("Name",vendorFields.Name);
        formData.append("Mobile_Number","91"+vendorFields.Mobile_Number?.replace("91",""));
        formData.append("Type_id",vendorFields.vType.Vendor_Id)
        // if it is  a valid http or has a valid value in photo
        const {Photo} = vendorFields
        if(Photo){
        let image = isValidUrl(Photo) ? Photo.replace(baseURL,"") : Photo
        formData.append("Photo",image)
        }
        // if new image is not same as old image and it is valid value,(not undefined or not null)
        if(content.Photo !== Photo && content.Photo){
        formData.append("oldImage",content.Photo)
        }

        await axiosInstance[HTTP_METHOD]("business/vendors/",formData,{
        headers:{
            'Content-Type': "multipart/form-data"
        }
        })
        .then((resp)=> {
            if(resp.id){
                onSave()
                let message = HTTP_METHOD === "put" ? "updated" : "inserted"
                Toaster("success",`Successfully ${message} the vendor`);
            }else{
                Toaster("error",resp)
            }
          }
        )
        .catch((err)=>Toaster("error",err))
        .finally(()=>setLoading(false))
    };


    return(
        <div className='flex flex-col p-4 m-3 w-full'>
            <SingleFileUpload image={vendorFields.Photo || ProfileAvatar}
                        handleChange={(Photo)=>setVendorFields((prev)=> ({...prev,Photo}) )} 
                        handleReset={()=> setVendorFields((prev)=> ({...prev,Photo:null}) ) }
                        fileInputRef={photoRef} imageClass={"shrink w-full h-40 rounded-2xl"}/>
            {
                ["Name","Mobile_Number"].map((field)=>(
                    
                    <input value={vendorFields[field]?.replace("91","")} key={field} onChange={(e)=>setVendorFields((prev)=> ({...prev,[field]:e.target.value}) )} 
                    placeholder={field}
                    className={`py-3 px-4 my-5 mx-auto  inline rounded-full outline-none w-[80%]
                               shadow-inner border-[1px] focus:border-gray-600 focus:ring-gray-600 border-gray-600`}
                    />
                    )
                )
            }

            <SelectReact label={"Vendor Type"} options={vendorTypeOptions} 
                                    value={vendorFields.vType} onChange={(value)=>setVendorFields((prev)=>({...prev,vType:value}))}
                                    getOptionLabel={(opt)=>opt.Vendor_type} getOptionValue={(opt)=>opt.Vendor_Id}/>

            <StdButton className={"w-fit p-3 m-auto"} disabled={loading} onClick={handleSave}>
                {loading ? <Loader /> :"Save"} 
            </StdButton>
        </div>
    )
}

const AdminVendor = () => {
    const [vendorList,setVendorList] = useState([]);
    const [loading,setLoading] = useState(false);

    const fetchVendorsList=()=>{
        setLoading(true);
        axiosInstance.get("business/vendors/",{
            params:{limit: 100,offset:0}
        })
        .then(setVendorList)
        .catch((err)=>Toaster("error",err))
        .finally(()=>setLoading(false))
    }

    useEffect(()=>{
        fetchVendorsList();
    },[]);

    const [modalContent,setModalContent] = useState({state:false});
    const onModalSave=()=>{
        fetchVendorsList();
        setModalContent({state:false})
    };

    const handleDelete=(vndrId)=>{
        axiosInstance.delete("business/vendors/"+vndrId)
        .then((resp)=>{Toaster(resp.status,resp.message); fetchVendorsList()})
        .catch((err)=>Toaster("error",err))
    }

  return (
    <Fragment>
        <div className="flex justify-between w-[80%] items-center mx-auto mt-7">
            <p className="text-2xl font-bold">Vendors</p>
            <StdButton className={"py-3"} onClick={()=>setModalContent({state:true})}>
                Add New Vendor
            </StdButton>
        </div>
        <ScrollableModal modalState={modalContent.state}
            title={ modalContent.Emp_Id ? "Update Vendor" : "Add New Vendor" } 
            content={<VendorModal content={modalContent} onSave={onModalSave}/>} 
            onClose={()=>setModalContent({state:false})}
            />
        <div className="grid grid-flow-row grid-cols-1  lg:grid-cols-4  gap-x-4 mt-7 w-[80%] mx-auto">
            {
                vendorList?.map((vndr)=>(
                    <div className="bg-white rounded-xl flex items-center flex-col p-6 m-2 w-3/4" key={vndr.id}>
                        <div className='flex justify-between w-full'>
                            <StdButton className={"group bg-white p-[0!important] m-0"} onClick={()=>handleDelete(vndr.id)}>
                                <DeleteIcon  className="size-5 mx-auto"/>
                                <ToolTip className=" left-5">Delete this</ToolTip>
                            </StdButton>
                            <StdButton className={"group bg-white p-[0!important] m-0"} onClick={()=>setModalContent({state:true,...vndr})}>
                                <EditIcon className="size-4"/>
                                <ToolTip>Edit this</ToolTip>
                            </StdButton>
                        </div>
                        <img src={vndr.Photo || ProfileAvatar} className="size-28 rounded-full" alt="manager"/>
                        <div className="font-black my-3">{vndr.Name}</div>
                        <div className="my-1">{vndr.vendor_type}</div>
                        <div className="font-semibold">{vndr.Mobile_Number}</div>
                    </div>
                ))
            }
        </div>
        {
        vendorList.length === 0 && !loading? <p className='text-center my-10 text-lg mx-auto w-full text-nowrap'>No Vendors enlisted. Add New Vendors</p> : null
        }
        {
            loading && <Loader />
        }
    </Fragment>
  )
}

export default AdminVendor