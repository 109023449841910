import React,{ useState} from 'react'
import LoginBot from "@images/login_bot.png";
import ErrorCircle from "@images/ErrorCircle.svg"
import axiosInstance from "apis/config";
import { diffbtwDates, Toaster } from '@constants/appConstants';
import { useAuth } from '../contexts/AuthContext';
import { Navigate,Link, useNavigate } from 'react-router-dom';
import CatermateLogo from "@images/catermate_logo.jpg"
import { Loader } from '@components/UtilityComponents';
import { PasswdEye } from '@images/sidebarIcons';
import { jwtDecode } from 'jwt-decode';

const initLogin = {email:"",password:"",emailError:false,passwordError:false};

const Login = () => {
    const [loginForm,setLoginForm] = useState({...initLogin});
    const [loading,setLoading] = useState(false);
    const [pwdToggle,setPwdToggle] = useState(false);

    const {login,isAuthenticated} = useAuth();
    const navigate = useNavigate();

    if(isAuthenticated){
        return <Navigate to="/" replace />
    }
    const handleChange=(e)=>{
        const {name,value} = e.target;
        setLoginForm((prev)=>({...prev,[name]:value}));
    };
 
    const handleSubmit=async()=>{
        setLoading(true);
        let loginFormCopy = {...loginForm};
       loginFormCopy.emailError = ! (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(loginFormCopy.email));
       loginFormCopy.passwordError = loginFormCopy.password.trim().length <= 5;

       if(loginFormCopy.emailError || loginFormCopy.passwordError){
           setLoginForm(loginFormCopy);
        }else{
           const resp = await axiosInstance.post("user/login",loginFormCopy)
                        .catch((ERR)=>Toaster("error",ERR))
                      
            if(resp?.message === "success"){
                let {sub} = jwtDecode(resp?.token);
                sub = JSON.parse(sub);
                if(sub.status === "completed"){
                    let daysRemaining = diffbtwDates(new Date(),sub.sub_end)
                    if(parseInt(daysRemaining.match(/\d+/)[0],10) < 15) //toast if less than 15 days
                    Toaster("error",daysRemaining+" Plan is expiring soon ",{autoClose:false})
                }else if(["pending","halted"].includes(sub.status)){
                    Toaster("error",`Your plan status is ${sub.status}. Please update payment method`,{autoClose:false})
                }
                setLoginForm({...initLogin});
                Toaster("success", "Succesfully Logged In!");
                login(resp.token);
                navigate("/",{replace:true});
            }
            
       }
       setLoginForm(loginFormCopy)
       setLoading(false);
    };

  return (
        <section id="login" className="grid grid-cols-2 grid-flow-col gap-0 rounded-full absolute m-auto top-0 bottom-0 left-0 right-0 min-w-36 min-h-36 max-h-fit max-w-fit">
            <div className="px-8 py-12 bg-white rounded-s-2xl max-lg:col-span-2">
                <img src={CatermateLogo} className="w-32 h-28" alt="logo"/>
                <h2 className="text-2xl font-medium">Welcome Back</h2>
                <p>Please enter your credentials to access your account</p>
                <form onSubmit={(e)=>e.preventDefault()}>
                <div className="my-7">
                <div>
                    <div className="relative">
                    <input type="text" id="email" autoComplete='email' name="email" placeholder="your@email.com" value={loginForm.email} onChange={handleChange}
                    className={`py-3 px-4 block w-full outline-none rounded-lg border-[2px] border-[#FFC092]
                    ${ loginForm.emailError && " border-red-500 rounded-lg  focus:border-red-500 focus:ring-red-500 "} `} 
                    required="" aria-describedby="email-helper" />
                    { loginForm.emailError &&
                    <div className="absolute inset-y-0 end-0 flex items-center pointer-events-none pe-3">
                        <img src={ErrorCircle} alt="error"/>
                    </div>}
                    </div>
                  { loginForm.emailError && <p className=" text-red-600 mt-2" id="email-helper">Please enter a valid email address.</p> }
                </div>


                <div className="max-w-sm my-3">
                <div className="relative">
                    <input autoComplete='current-password' id="hs-toggle-password-login" type={pwdToggle ? "text" : "password"} name="password" onChange={handleChange}
                    className={`py-3 ps-4 pe-10 block w-full border-[2px] border-[#FFC092] rounded-lg  outline-none  
                                    ${loginForm.passwordError && " border-red-500 focus:border-red-500 focus:ring-red-500 "} `}
                    placeholder="your password" value={loginForm.password}/>
                    <button type="button" onClick={()=>setPwdToggle((prev)=>!prev)} data-hs-toggle-password='{
                        "target": "#hs-toggle-password-login"
                    }' className={`absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer 
                        text-gray-400 rounded-e-md focus:outline-none focus:text-[#FFC092] ${ pwdToggle && " active "}`}>
                    <PasswdEye  className="shrink-0 size-3.5"/>
                    {loginForm.passwordError && <img src={ErrorCircle} alt="error"/>}
                    </button>
                </div>
                {  loginForm.passwordError && <p className=" text-red-600 mt-2" id="password-helper">Please enter a valid Password of atleast 6 characters.</p>}
                </div>
                <Link to="/forgot-password" className='cursor-pointer underline w-fit'>Forgot Password? </Link>
                </div>

                <button className="font-semibold text-xl bg-[#FFC092] w-full mb-4 p-4 rounded-2xl" disabled={loading} onClick={handleSubmit}>
                    {
                        loading ? <Loader /> : "Login"
                    }
                </button>
                </form>
                <p>Not registered? <Link to="/signup" className="text-[#776254] font-bold underline"> Create account </Link></p>
                
            </div>
            <div className="rounded-2xl max-lg:hidden">
                <img src={LoginBot} alt="login bot" className="h-full rounded-e-2xl"/>
            </div>
        </section>
  )
}

export default Login