import React, { Fragment } from "react";
import Header from "@components/Header";
import Footer from "@components/Footer";
import Hero from "@components/Hero";
import TryOutYourselfSection from  "@components/TryOutYourselfSection";
import AboutUs from "@components/AboutUs";
import Features from "@components/Features";
import FrequentAskedQues from "@components/FAQ";
import Testimonial from "@components/Testimonial";
import PricingPlans from "@components/PricingPlans";
import ContactUs from "@components/ContactUs";

const HomePage = () => {


  return (
    <Fragment>
      <Header />
        <main className="h-auto min-h-[500vh]  lg:m-4 max-lg:m-2 top-10 relative left-[50%] -translate-x-1/2 xl:overflow-x-hidden">
          <Hero />
          <TryOutYourselfSection />
          <AboutUs />
          <Features />
          <FrequentAskedQues />
          <Testimonial />
          <PricingPlans />
          <ContactUs />
        </main>
      <Footer />
    </Fragment>
  )
}

export default HomePage