import React,{Fragment, useEffect, useState} from 'react'
import { Link, Outlet } from 'react-router-dom'
import { DownCollapse, UpCollapse, RightArrow,UpArrow,LogoutIcon } from "@images/sidebarIcons";
import { SuperAdminSideBarMenus,findObjectByKeyValue } from '@constants/appConstants';
import ProfileAvatar from "@images/profile_avatar.png";
import { useAuth } from "features/contexts/AuthContext";
import { TextTruncateTooltip } from '@components/UtilityComponents';

const MenuItem=({link,icon,menu,children,level=0,activeMenu})=>{
  if(children){
    return (
        <li className="hs-accordion" id={`${menu.replace(" ","-")}-accordion${level > 0 ? "-sub-"+level : ""}`}>
            <button className="hs-accordion-toggle w-full text-start menu-item"
                    aria-expanded="true" aria-controls={`${menu.replace(" ","-")}-accordion-child`}>
                {icon}
                {menu}
                <UpCollapse />
                <DownCollapse />
            </button>
            <div id={`${menu.replace(" ","-")}-accordion${level > 0 ? "-sub-"+level : ""}-child`} 
                aria-labelledby={`${menu.replace(" ","-")}-accordion${level > 0 ? "-sub-"+level : ""}`}
                  className={`hs-accordion-content w-full overflow-hidden transition-[height] duration-300 block`}>
                    <ul className={`ps-${(8+level)} pt-1 space-y-1`}>
                        {
                          children.map((item)=>{
                            item.level = level+1;
                            item.activeMenu = activeMenu;
                            return <Fragment key={item.menu}> {MenuItem({...item})}</Fragment>
                          })
                        }
                    </ul>
            </div>
        </li>
    )
  }else{
    return(
      <li>
        <button className="w-full">
        <Link  to={link}
          className={` menu-item ${activeMenu===menu ? " active-side-menu " : ""}`}>
            <span className={`flex gap-1`} style={level > 0 ? {fontWeight:"500" , fontSize: "14px"} : null}>
              {icon !== undefined && icon}
              {menu}
            </span> 
          {activeMenu===menu ? <RightArrow />:null}
        </Link>
        </button>
      </li>
    )
  }
}

const SuperAdminRootLayout = () => {
  let {location} = window;
  
  const [activeMenu,setActiveMenu] =  useState("Dashboard");
  
  const {logout,user} = useAuth();

  useEffect(()=>{
    window.HSStaticMethods?.autoInit();
    let currentMenu = findObjectByKeyValue(
      [...SuperAdminSideBarMenus],location.pathname,"link"
    )
   if(currentMenu)
    setActiveMenu(currentMenu.menu)
  },[location.pathname]);

  return (
    <div className="lg:w-full lg:grid lg:grid-cols-12">
      <style>
        {
          `
            .menu-item{
              background-color: transparent;
              transition: background-color 0.5s ease;
              font-weight: 600;
              display: flex;
              align-items: center;
              column-gap: 0.875rem;
              padding-top: 0.5rem /* 12px */;
              padding-bottom: 0.5rem /* 12px */;
              padding-left: 0.625rem /* 10px */;
              padding-right: 0.625rem /* 10px */;
              border-radius: 0.5rem;
            }

            .menu-item:hover,.menu-item:focus{
             background-color:  #f3f4f6 ;
             outline: none;
            }

            .active-side-menu{
              background-color: #FEF1E1;
              width: 100%;
              border-start-start-radius: 9999px;
              border-end-start-radius: 9999px;
              justify-content: space-evenly;
              font-weight: 700;
              animation: fadeInBg 0.5s ease forwards;
            }
            
            
            .active-side-menu:hover{
              background-color: #FEF1E1;
            }
            .active-side-menu:focus{
              background-color: #FEF1E1;
            }
            .active-side-menu::after{
                content: "";
                position: absolute;
                right: 0px;
                top: auto;
                width: 15px; /* Adjust to control the depth of the cutout */
                height: 55px;
                background-color: #FEF1E1; /* Sidebar background color */
                border-top-left-radius: 15px; /* Adjust for the inward curve */
                border-bottom-left-radius: 15px;
            }

            @keyframes fadeInBg {
                0% {
                    background-color: #FEF1E100; /* Fully transparent */
                }
                100% {
                    background-color: #FEF1E1; /* Fully opaque */
                }
            }
          `
        }
      </style>
        <div className="lg:col-span-2 ">
          <div className="sticky top-0 inset-x-0 z-20 bg-white border-y px-4 sm:px-6 lg:px-8 lg:hidden">
            <div className="flex items-center py-2">
              
              <button type="button" className="size-8 flex justify-center items-center gap-x-2 border border-gray-200 text-gray-800 hover:text-gray-500 rounded-lg focus:outline-none focus:text-gray-500 disabled:opacity-50 disabled:pointer-events-none" 
                  aria-haspopup="dialog" aria-expanded="false" aria-controls="hs-application-sidebar" aria-label="Toggle navigation" data-hs-overlay="#hs-application-sidebar">
                <span className="sr-only">Toggle Navigation</span>
                <svg className="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><rect width="18" height="18" x="3" y="3" rx="2"/><path d="M15 3v18"/><path d="m8 9 3 3-3 3"/></svg>
              </button>
              

              
              <ol className="ms-3 flex items-center whitespace-nowrap">
                <li className="flex items-center text-sm text-gray-800">
                  Catermate AI
                  <svg className="shrink-0 mx-3 overflow-visible size-2.5 text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 1L10.6869 7.16086C10.8637 7.35239 10.8637 7.64761 10.6869 7.83914L5 14" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
                  </svg>
                </li>
                <li className="text-sm font-semibold text-gray-800 truncate" aria-current="page">
                  {activeMenu}
                </li>
              </ol>
              
            </div>
          </div>

          <div id="hs-application-sidebar" className="hs-overlay  [--auto-close:lg]
            hs-overlay-open:translate-x-0
            -translate-x-full transition-all duration-300 transform
            hidden w-[260px]
             h-full
            fixed inset-y-0 start-0 z-[60]
            bg-white border-e border-gray-200
            lg:block lg:translate-x-0 lg:end-auto lg:bottom-0
          "  tabIndex="-1" aria-label="Sidebar">
            <div className="relative flex flex-col h-full max-h-full">
              
              {/* Logo part */}
              <div className="px-6 pt-4">
                
                <Link className="flex-none rounded-xl text-xl inline-block font-semibold focus:outline-none focus:opacity-80" to="/" aria-label="Preline">
                  Catermate AI
                </Link>
                
              </div>

              {/*Middle Menus Part*/}
              <div className="h-[85%] overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300">
                <nav className="hs-accordion-group p-3 w-full flex flex-col flex-wrap" data-hs-accordion-always-open>
                  <ul className="flex flex-col space-y-1">
                    {
                      SuperAdminSideBarMenus.map((item)=>{
                        item.activeMenu = activeMenu;
                        return <Fragment key={item.menu}>  {MenuItem({...item})} </Fragment>
                      })
                    }
                  </ul>
                </nav>

                {/*Bottom bar*/}
                

                <div className="hs-dropdown h-fit [--placement:top-right] inline-flex absolute bottom-0 w-full">
                  <button id="hs-dropup" type="button" 
                  className="inset-6 w-full hs-dropdown-toggle py-4 px-2 inline-flex items-center gap-x-2 text-sm font-medium 
                              rounded-lg border-[1.5px] bg-white text-gray-800 " 
                  aria-haspopup="menu" aria-expanded="false" aria-label="Dropdown">
                  <img src={user?.photo || ProfileAvatar} crossOrigin='use-credentials' alt="profile" className="rounded-full w-14 h-14 inline"/>
                  <TextTruncateTooltip content={user?.username} 
                          className="truncate max-w-full whitespace-nowrap h-fit text-xl mx-auto"/>
                    <UpArrow className={"hs-dropdown-open:rotate-180 size-6 font-bold mx-auto"}/>
                  </button>

                  <div className="w-full hs-dropdown-menu transition-[opacity,margin] border-[0.7px] border-gray-400
                   duration hs-dropdown-open:opacity-100 opacity-0 hidden min-w-60 bg-white shadow-md rounded-lg mt-2" 
                        role="menu" aria-orientation="vertical" aria-labelledby="hs-dropup">
                    <div className="p-1 space-y-0.5">
                  
                          <Link className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-[15px] font-medium
                                hover:bg-gray-100 focus:outline-none focus:bg-gray-100" key={"/login"}
                            to={"/login"} onClick={()=>logout()} >
                            Log out <LogoutIcon />
                            
                          </Link>
                         
                    </div>
                  </div>
                </div>
              </div>  
              
            </div>
          </div>

          {/* <div className="w-full pt-10 px-4 sm:px-6 md:px-8 lg:ps-72">
          </div> */}
        </div>
        <main className=" lg:col-span-10 w-full overflow-y-auto overflow-x-hidden min-h-[100vh]">
          <Outlet />
        </main>
    </div>
  )
}

export default SuperAdminRootLayout