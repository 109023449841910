import Footer from '@components/Footer'
import Header from '@components/Header'
import React, { Fragment } from 'react'

const RefundPolicy = () => {
  return (
    <Fragment>
        <style>
            {
                `
                    ol {
                        list-style: numeric;
                    }
                    ul {
                        list-style: disc;
                    }
                    ol li {
                        margin: 3rem 0;
                    }

                   li ul li{
                        margin: 0.5rem 0;
                    }

                   #cancellation-refunds{
                        font-size: 21px;
                    }
                    #cancellation-refunds a{
                        text-decoration:underline;
                    }
                `
            }
        </style>
        <Header />
        <main className='relative top-28 pt-10 max-w-max mx-0 min-h-[90vh] mb-32'>
        <section id="cancellation-refunds" className='my-14'>
            <p className='text-4xl my-7 text-nowrap text-center font-semibold w-[60%] lg:mx-auto h-[-webkit-fill-available]'>
                Refund and Cancellation policy
            </p>
            <p className='w-[70%] mx-auto'>
                At Catermate AI, we are committed to ensuring a smooth and transparent shopping experience. 
                While we do not offer refunds, customers have the option to cancel their orders within the specified timeframe. 
                Please review the details below to understand our policy.

            </p>
            <ol className='w-[70%] mx-auto'>
                <li>
                    <p className='font-semibold'>Refund Policy</p>
                    <div>
                    We regret to inform you that no refunds are offered on any products or services purchased through our platform.
                    </div>
                    <ul className="mx-[5%]">
                        <li>All sales are considered final once the purchase is confirmed.</li>
                        <li>Customers are encouraged to review their orders carefully before completing their purchase.</li>
                    </ul>
                </li>
                <li>
                    <p className='font-semibold'>Cancellation Policy</p>
                    <div className='font-semibold'>Order Cancellations</div>
                    <ul className="mx-[5%]">
                        <li>Customers may cancel their orders under the following conditions:
                            <ul className="mx-[5%]">
                                <li>Cancellation requests are made within 15 days of placing the order.</li>
                                <li>The product has not been shipped or the service has not been initiated.</li>
                            </ul>
                        </li>
                    </ul>
                    <div className='font-semibold'>How to Cancel an Order:</div>
                    <ul className="mx-[5%]">
                        <li>To request a cancellation, contact us:</li>
                        <ul className="mx-[5%]">
                            <li>Email: <a href="mailto:info@catermateai.com" target="_blank" rel="noreferrer">info@catermateai.com</a></li>
                            <li>Phone: <a href="tel:+919655445481">+91 96554 45481</a></li>
                            <li>Include your order number and contact details in the request.</li>
                        </ul>
                    </ul>
                    <div className='font-semibold'>Post-Cancellation Process:</div>
                    <ul className="mx-[5%]">
                        <li>Upon approval of a cancellation request:</li>
                        <ul className="mx-[5%]">
                            <li>Any payments made will be converted into store credits for future purchases.</li>
                            <li>No monetary refunds will be issued.</li>
                        </ul>
                    </ul>
                </li>
                <li>
                    <p className='font-semibold'>Non-Cancellable Items</p>
                    <div>Certain items and services cannot be canceled once purchased, including:</div>
                    <ul className="mx-[5%]">
                        <li>Digital goods and downloadable content.</li>
                        <li>Custom-made or personalized products.</li>
                        <li>Subscription-based services after activation.</li>
                        <li>Perishable goods such as food items.</li>
                    </ul>
                </li>
                <li>
                    <p className='font-semibold'> Additional Guidelines</p>
                    <ul className="mx-[5%]">
                        <li>Cancellations are subject to the timelines and conditions specified above.</li>
                        <li>Once a product is shipped or the service is initiated, cancellations will not be entertained.</li>
                        <li>Store credits issued after cancellations are non-transferable and valid for 6 months from the date of issue.</li>
                    </ul>
                </li>
                <li>
                    <p className='font-semibold'>Frequently Asked Questions</p>
                    <ol className="mx-[5%]">
                        <li>
                            <p className='font-semibold'>Why don’t you offer refunds?</p>
                            <div>
                            Our policy ensures fairness and transparency for all customers by avoiding logistical complexities and aligning with our business model.
                            </div>
                        </li>
                        <li>
                            <p className='font-semibold'> Can I modify my order instead of canceling it?</p>
                            <div>
                            Order modifications are subject to approval and can only be requested within 24 hours of placing the order.
                            </div>
                        </li>
                        <li>
                            <p className='font-semibold'>What happens if I receive a damaged or defective product?</p>
                            <div>
                            If you receive a damaged or defective product, please contact us immediately at info@catermateai.com with photos of the issue. We will replace the product at no extra cost.
                            </div>
                        </li>
                        <li>
                        <p className='font-semibold'>What are store credits?</p>
                            <div>
                            Store credits are an alternative to refunds, allowing you to purchase products of equivalent value from our store. Store credits will be provided for approved cancellations.
                            </div>
                        </li>
                    </ol>
                </li>
                <li>
                    <p className='font-semibold'> Contact Us</p>
                    <ul className="mx-[5%]">
                    <li>Email: <a href="mailto:info@catermateai.com" target="_blank" rel="noreferrer">info@catermateai.com</a></li>
                    <li>Contact: <a href="tel:+919655445481">+91 96554 45481</a></li>
                    </ul>
                </li>
            </ol>
            </section>
        </main>
        <Footer />
    </Fragment>
  )
}

export default RefundPolicy