import { toast, Zoom } from "react-toastify";
import { DashboardIcon } from "@images/sidebarIcons";

export const Toaster=(type,message,overrides={})=>{
  let shortMessage = message
  if(typeof shortMessage!=="string"){
    shortMessage = message?.response?.data || message?.response?.message || message?.message
  }
    return toast[type](shortMessage,{
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Zoom,
        ...overrides
    })
}

export const findObjectByKeyValue=(ArrayofObj, valueToFind,keyName) =>{
  
  
    for (const item of ArrayofObj) {
      // Check if the current object has a matching key-value pair
      if (item[keyName] === valueToFind) {
        return item;
      }
  
      // If the object has children, search recursively in those children
      if (item.children) {
        const result = findObjectByKeyValue(item.children,valueToFind,keyName);
        if (result) {
          return result;
        }
      }
    }
  
    return null; // Return null if no match is found
  }


export const SuperAdminSideBarMenus=[
  {
    menu: "Dashboard",
    link: "/",
    icon: <DashboardIcon />
  },
  {
    menu: "Plans",
    link: "/plans-list",
    icon: <DashboardIcon />
  }
]

export const  isValidUrl=(input)=>{
    // Check if input is a string and not an object or fake path
    if (typeof input !== 'string') {
        return false;
    }
    // Check for valid URL
    try {
        const url = new URL(input);
        // Optionally, check if the URL is a valid HTTP/HTTPS URL
        return url.protocol === 'http:' || url.protocol === 'https:';
    } catch (e) {
        return false; // Not a valid URL
    }
}

export const PlanDetails = [
  {
      plan_name: "Basic",
      amount_in_paise: "0",
      description: `Our Basic Membership is perfect for lead generation via WhatsApp, covering event creation, menu selection for customers, and efficient client management for businesses.`,
      perks: ["Client Management","Event Creation & Menu Selection","Lead Generation"],
      bg_color: " from-[#f98889] to-[#a73b3d] "
  },
  {
      plan_name: "Premium",
      amount_in_paise: "1000",
      description: `Our Premium Membership includes all Basic plan features, plus menu consolidation, streamlining ingredient planning and execution for faster and more efficient operations.`,
      perks: ["All In Basic Plan Plus","Menu Consolidation for Efficient Ingredient Planning"],
      bg_color: " from-[#f1a36c] to-[#ff6d03] ",
      razorpay_plan_id: "plan_PKL7uy5vnpo3Z6",
  },
  {
      plan_name: "Elite",
      amount_in_paise: "2000",
      description: `Our Elite Membership includes all Premium features,  comprehensive vendor management , 
                      customized PDFs to deliver it vendors,simplifying coordination for seamless event execution.`,
      perks: ["All In Premium Plan Plus","Vendor Management with  PDF Reports"],
      bg_color: " from-[#7fcd66] to-[#4d9f32] ",
      razorpay_plan_id: "plan_PKLI4NS3VzvWOX"
  },
  
]

export const diffbtwDates=(startDate=new Date(),futureDate=new Date())=>{
  const timeDifference = new Date(futureDate) - new Date(startDate);
  const daysDifference = timeDifference / (1000*3600*24)
  return `${Math.ceil(daysDifference)} days remaining`
}