import React, { Fragment,useEffect,useRef,useState } from 'react'
import ProfileAvatar from "@images/profile_avatar.png";
import {DeleteIcon, EditIcon} from "@images/sidebarIcons";
import axiosInstance, { baseURL } from 'apis/config';
import { Loader, ScrollableModal, SingleFileUpload, StdButton, ToolTip } from '@components/UtilityComponents';
import { isValidUrl, Toaster } from '@constants/appConstants';


const ManagerModal=({content,onSave})=>{
    const [managerFields,setManagerFields] = useState(content)
    const [loading,setLoading]=useState(false)

    const photoRef = useRef();

    const handleSave=async()=>{
        if(managerFields.Emp_name.trim() <=2 || !managerFields.Emp_MobileNumber?.replace("91","")){
            Toaster("error","Please Enter valid name and Number")
            return;
        }
        setLoading(true);
        const HTTP_METHOD = content.Emp_Id ? "put" : "post";
        const formData = new FormData();
        if(content.Emp_Id)
        formData.append("Emp_Id",content.Emp_Id);

        formData.append("Role_name","manager")
        formData.append("Emp_name",managerFields.Emp_name);
        formData.append("Emp_MobileNumber","91"+managerFields.Emp_MobileNumber?.replace("91",""));
        
        // if it is  a valid http or has a valid value in photo
        const {Photo} = managerFields
        if(Photo){
        let image = isValidUrl(Photo) ? Photo.replace(baseURL,"") : Photo
        formData.append("Photo",image)
        }
        // if new image is not same as old image and it is valid value,(not undefined or not null)
        if(content.Photo !== Photo && content.Photo){
        formData.append("oldImage",content.Photo)
        }

        await axiosInstance[HTTP_METHOD]("business/managers/",formData,{
        headers:{
            'Content-Type': "multipart/form-data"
        }
        })
        .then((resp)=> {
            if(resp.Emp_Id){
                onSave()
                let message = HTTP_METHOD === "put" ? "updated" : "inserted"
                Toaster("success",`Successfully ${message} the manager`);
            }else{
                Toaster("error",resp)
            }
          }
        )
        .catch((err)=>Toaster("error",err))
        .finally(()=>setLoading(false))
    }

    return(
        <div className='flex flex-col p-4 m-3 w-full'>
            <SingleFileUpload image={managerFields.Photo || ProfileAvatar}
                        handleChange={(Photo)=>setManagerFields((prev)=> ({...prev,Photo}) )} 
                        handleReset={()=> setManagerFields((prev)=> ({...prev,Photo:null}) ) }
                        fileInputRef={photoRef} imageClass={"shrink w-full h-40 rounded-2xl"}/>
            {
                ["Emp_name","Emp_MobileNumber"].map((field)=>(
                    
                    <input value={managerFields[field]?.replace("91","")} key={field} onChange={(e)=>setManagerFields((prev)=> ({...prev,[field]:e.target.value}) )} 
                    placeholder={field}
                    className={`py-3 px-4 my-5 mx-auto  inline rounded-full outline-none w-[80%]
                               shadow-inner border-[1px] focus:border-gray-600 focus:ring-gray-600 border-gray-600`}
                    />
                    )
                )
            }

            <StdButton className={"w-fit p-3 m-auto"} disabled={loading} onClick={handleSave}>
                {loading ? <Loader /> : "Save"} 
            </StdButton>
        </div>
    )
}

const AdminManager = () => {
    const [managerList,setManagerList] = useState([]);
    const [loading,setLoading] = useState(false);

    const fetchManagersList=()=>{
        setLoading(true);
        axiosInstance.get("business/managers/",{
            params: {limit: 100, offset:0}
        })
        .then(setManagerList)
        .catch((err)=>Toaster("error",err))
        .finally(()=>setLoading(false))
    }

    useEffect(()=>{
        fetchManagersList()
    },[]);

    const [modalContent,setModalContent]=useState({state:false})
    
    const onModalSave=()=>{
        fetchManagersList();
        setModalContent({state:false})
    }

    const handleDelete=(mngrId)=>{
        axiosInstance.delete("business/managers/"+mngrId)
                    .then((resp)=>{Toaster(resp.status,resp.message); fetchManagersList()})
                    .catch((err)=>Toaster("error",err))
    }
  return (
    <Fragment>
        <div className="flex justify-between w-[80%] items-center mx-auto mt-7">
            <p className="text-2xl font-bold">Managers</p>
            <StdButton className={"py-3"} onClick={()=>setModalContent({state:true})}>
                Add New Manager
            </StdButton>
        </div>
        <ScrollableModal modalState={modalContent.state}
            title={ modalContent.Emp_Id ? "Update Manager" : "Add New Manager" } 
            content={<ManagerModal content={modalContent} onSave={onModalSave}/>} 
            onClose={()=>setModalContent({state:false})}
            />
        <div className="grid grid-flow-row grid-cols-1  lg:grid-cols-4  gap-x-4 mt-7 w-[80%] mx-auto">
            {
                managerList?.map((mngr)=>(
                    <div className="bg-white rounded-xl flex items-center flex-col p-6 m-2 w-3/4" key={mngr.Emp_Id}>
                        <div className='flex justify-between w-full'>
                        <StdButton className={"group bg-white p-[0!important] m-0"} onClick={()=>handleDelete(mngr.Emp_Id)}>
                            <DeleteIcon  className="size-5"/>
                            <ToolTip>Delete this</ToolTip>
                        </StdButton>
                        <StdButton className={"group bg-white p-[0!important] m-0"} onClick={()=>setModalContent({state:true,...mngr})}>
                            <EditIcon className="size-4"/>
                            <ToolTip>Edit this</ToolTip>
                        </StdButton>
                        </div>
                        <img src={mngr.Photo || ProfileAvatar} className="size-28 rounded-full" alt="manager"/>
                        <div className="font-black my-3">{mngr.Emp_name}</div>
                        <div className="font-semibold">{mngr.Emp_MobileNumber}</div>
                    </div>
                ))
            }
        </div>
        {
        managerList.length === 0 && !loading? <p className='text-center my-10 text-lg mx-auto w-full text-nowrap'>No Managers enlisted. Add New Managers</p> : null
        }
        {
            loading && <Loader />
        }
    </Fragment>
  )
}

export default AdminManager