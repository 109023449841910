import React from 'react'
import CatermateLogo from "@images/catermate_logo.jpg"
import { Link } from 'react-router-dom'

const HeaderLink=({menu,link})=>{

  return(
    <Link className="font-bold flex max-lg:text-[1.3rem] mx-3.5 items-center text-gray-800 rounded-lg 
                  focus:outline focus:outline-blue-100 focus:text-blue-700" 
      to={link} aria-current="page">
      {menu}
    </Link>
  )
}

const Header=()=>{

  return(
  <header className="flex flex-wrap lg:rounded-lg  lg:justify-between lg:flex-nowrap z-50  max-lg:min-w-[105%] mx-auto bg-red-500 lg:bg-[#FFFAF6]   shadow-md fixed max-lg:-top-2 lg:top-4 lg:w-[85%] left-[50%] -translate-x-1/2">
    <nav className=" relative max-w-[99rem] min-w-[-webkit-fill-available] mx-10px lg:flex lg:items-center lg:justify-between lg:gap-3 py-2 lg:px-8 max-lg:py-0">
      <div className="max-lg:p-2 flex w-fit max-lg:w-full max-lg:justify-evenly items-center gap-x-1">
        <Link className=" lg:w-[7rem] lg:text-[1.1rem] basis-full font-semibold text-xl text-black focus:outline-none focus:opacity-80" to="/" aria-label="Brand">
          <img  src={CatermateLogo} className='w-full h-full max-lg:w-[15%] max-lg:h-[15%]' alt="logo"/>
        </Link>

        {/* <!-- Collapse Button --> */}
        <button type="button" className="mx-4 hs-collapse-toggle lg:hidden relative size-9 flex justify-center items-center font-medium text-[12px] rounded-lg border border-gray-200 text-gray-800 bg-gray-100 focus:outline-none  disabled:opacity-50 disabled:pointer-events-none" id="hs-header-base-collapse"  aria-expanded="false" aria-controls="hs-header-base" aria-label="Toggle navigation"  data-hs-collapse="#hs-header-base" >
          <svg className="hs-collapse-open:hidden size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="3" x2="21" y1="6" y2="6"/><line x1="3" x2="21" y1="12" y2="12"/><line x1="3" x2="21" y1="18" y2="18"/></svg>
          <svg className="hs-collapse-open:block shrink-0 hidden size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
          <span className="sr-only">Toggle navigation</span>
        </button>
        {/* <!-- End Collapse Button --> */}
      </div>

      {/* <!-- Collapse --> */}
      <div id="hs-header-base" className=" max-h-[50vh] max-lg:max-h-fit max-lg:bg-[#F5B98D] max-lg:rounded-t-2xl hs-collapse hidden overflow-hidden transition-all duration-300 basis-full grow lg:block "  aria-labelledby="hs-header-base-collapse" >
        <div className=" overflow-y-auto max-h-[50vh] [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300">
          <div className="py-2 lg:py-0  flex flex-col lg:flex-row lg:items-center gap-0.5">
          
              <div className="flex max-lg:min-h-[35vh] grow flex-col lg:flex-row max-lg:justify-evenly lg:justify-center lg:items-center gap-3">
                
                {
                  [
                    {menu:"HOME",link:"/"},
                    {menu:"ABOUT",link:"#about-us"},
                    {menu:"FEATURES",link:"#features"},
                    {menu:"SUBSCRIBE",link:"#subscribe"},
                    {menu:"SIGN UP",link:"/signup"}
                  ].map(({menu,link})=><HeaderLink key={menu} menu={menu} link={link}/>)
                }
                
                {/* <!-- Dropdown --> */}
                
              </div>
          
              <Link style={{letterSpacing:"2px",borderWidth:"medium",borderStyle:"groove", borderColor:"#13FB1A"}} 
                className="py-2 px-2.5 my-2 max-lg:min-w-fit max-lg:max-w-4 mx-auto text-center lg:max-w-fit font-semibold items-center text-sm rounded-full bg-[#56CB4B] 
                          hover:bg-[#93FA8A] focus:outline-none focus:bg-[#93FA8A] disabled:opacity-50 disabled:pointer-events-none" 
                to="/login">
                SIGN IN
              </Link>
          
            {/* <!-- End Button Group --> */}
          </div>
        </div>
      </div>
      {/* <!-- End Collapse --> */}
    </nav>
  </header>
  )
}

export default Header;
