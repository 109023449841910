import React, { useState,Fragment,useEffect } from 'react'
import axiosInstance from 'apis/config';
import { Toaster } from '@constants/appConstants';
import { TableCellWithToolTip } from '@components/Table';
import Pagination from '@components/Pagination';

const AdminUpcomingOrders = () => {
    const [currentPage,setCurrentPage] = useState(1);
    const [pageSize,setPageSize] = useState(10);
    const [totalCount,setTotalCount] = useState(0);

    const [upOrdersList,setUpOrdersList] = useState([]);
    
    const columns=[{name: "Name",key:"Client_Name"},{name: "Occasion",key:"Event_Name"},{name: "Start Date",key:"Start_Date"},
        {name: "Venue",key:"Event_Address"},{name:"Client Number",key:"Client_Contact_Number"}]
    useEffect(()=>{
        axiosInstance.get("order/upcoming-orders",{
            params: {
                Limit: pageSize,
                Offset: ((currentPage-1)*pageSize),
                Menu_Name: ""
            }
        })
        .then((data)=>{
            if(data[0]?.total_count){
                const totalRows  = data[0].total_count;
                setUpOrdersList(data);
                setTotalCount(totalRows)
            }else{
                Toaster("error",data)
            }
        })
        .catch((err)=>Toaster("error",err))
    },[currentPage,pageSize]);

  return (
    <Fragment>
        <div className="flex justify-between w-[80%] items-center mx-auto mt-7">
            <p className="text-2xl font-bold place-content-start">Upcoming Orders</p>
        </div>
        <div className=" w-[80%] mx-auto my-7">
            <div className="rounded-2xl relative z-30 overflow-x-hidden max-h-[80vh] overflow-y-scroll shadow-md">
                <table className="rounded-2xl table-fixed relative min-w-full">
                    <thead className="rounded-2xl">
                        <tr className="bg-[#FFC092] rounded-2xl sticky top-0">
                            {
                                columns.map((col)=>(
                                    <TableCellWithToolTip key={col.name} 
                                        className="p-4 text-center truncate whitespace-nowrap overflow-hidden max-w-[150px]"
                                        content={col.name} header/>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            upOrdersList.map((row,rowIndex)=>(
                                <tr key={row.Event_ID} className={`p-2 ${rowIndex%2 === 0 && " bg-white "}`}>
                                        <td className="p-4 text-center truncate max-w-[150px]">
                                            { ( (currentPage-1) * pageSize) + rowIndex+1}
                                        </td>
                                    {
                                        columns.slice(1).map((col)=>(
                                            <TableCellWithToolTip key={col.key} 
                                            className="p-4 text-center truncate whitespace-nowrap overflow-hidden max-w-[150px]"
                                            content={row[col.key]} />
                                        ))
                                    }
                                </tr>
                            ))
                        }
                    </tbody>
                    <tfoot>
                    <tr className="bg-white border-t-[1px] border-gray-300 sticky bottom-0">
                    <td className='text-center p-4' colSpan={columns.length}>
                        <Pagination
                            currentPage={currentPage}
                            totalCount={totalCount}
                            pageSize={pageSize}
                            onPageSizeChange={setPageSize}
                            onPageChange={page => setCurrentPage(page)}
                        />
                    </td>
                    </tr>
                </tfoot>
                </table>
            </div>
            
        </div>
    </Fragment>
  )
}

export default AdminUpcomingOrders