import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import QuoteImage from "@images/quote.png";

const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1044 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1044, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  };

const testaments = Array(1).fill(
    {
        name: "Vidya D.",
        content: `This tool has been incredibly helpful in managing my clients event bookings. 
                    The menu management process is straightforward and efficient, making my job much easier.`
    }
    );

const Testimonial = () => {
  return (
        <section id="testimonials" className="relative my-7 2xl:mx-[8%] px-4 ">
            <style>
                {
                `
                #testimonials .custom-carousel-dots{
                    position: relative;
                    top: 14px;
                }

                #testimonials .react-multi-carousel-dot button{
                    background-color: #C72122;
                    border-color: #C72122; 
                    height: 0px;
                    width: 15px;  
                    border-radius: 5px;
                    opacity: 0.5;
                    }
                #testimonials .react-multi-carousel-dot--active button{
                    background-color: #C72122;
                    border-color: #C72122; 
                    width: 45px;
                    opacity: 1;
                    }
                    .custom-carousel-container{
                      justify-content:center;
                    }
                `
                }
            </style>
            <h1 className="text-[#D51037] text-4xl  font-semibold text-nowrap">Testimonials</h1>
            <p className="font-medium xl:max-w-[80%] mt-[1rem] text-[1.1rem] ">
                Take a look at our happy clients! 
                We pride ourselves on delivering exceptional service and personalized solutions that exceed expectations 
                and we're grateful for the trust they place in us.
            </p>
            <div className="my-5 relative mx-auto">
                <Carousel responsive={responsive}
                // autoPlay={true}
                autoPlaySpeed={3000} 
                infinite={true}
                removeArrowOnDeviceType={["tablet","mobile","desktop","superLargeDesktop"]}
                showDots={true}
                renderDotsOutside={true}
                dotListClass='custom-carousel-dots'
                draggable
                pauseOnHover
                containerClass='custom-carousel-container'
                renderButtonGroupOutside={true}>
                    {
                        testaments.map((elem,index)=>{
                           return( 
                           <div className="bg-white rounded-lg w-fit mx-2 h-[175px] p-3" key={index}>
                                <p>{elem.content}</p>
                                <div className="m-3 font-bold">{elem.name}</div>
                                <img src={QuoteImage} alt="quote" className="w-15 h-10 relative left-[80%] bottom-[20%]"/>
                            </div> 
                            )
                        })
                    }
                </Carousel>
            </div>
        </section>
  )
}

export default Testimonial