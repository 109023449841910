import { Loader, ScrollableModal, StdButton } from '@components/UtilityComponents';
import { diffbtwDates, Toaster } from '@constants/appConstants';
import axiosInstance from 'apis/config';
import { useAuth } from 'features/contexts/AuthContext';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import CatermateLogo from "@images/catermate_logo.jpg"

const modalInit={
    state : false,
    title: "",
    content: "",
}

const PurchaseButton=({item})=>{
    const className = `bg-white text-black rounded-full w-[80%] block p-3 mt-[5%] mx-[10%] relative 
                        top-[5%] cursor-pointer disabled:cursor-not-allowed disabled:text-white`

    const {user,login} = useAuth();
    const navigate=useNavigate();
    const isPurchased=(planName)=>user?.company_plan===planName
    const [paymentProcessing,setPaymentProcessing]=useState(false)
    const [modalState,setModalState] = useState({...modalInit})

    const purchaser = useMemo(()=>{
        return user ? {user_id:user?.user_id,company_id:user?.company_id} : {}
    },[user])

    const paymentVerification = useCallback(async (signatureResponse) =>{
       await axiosInstance.post("payments/verify-subscription",{
                signatureResponse,
                purchaser
            })
        .then((resp)=>{
                if(resp.message==="success"){
                    Toaster("success","Subscription Purchased Successfully");
                    login(resp.token);
                    window?.location?.reload()
                }else{
                    Toaster("error","Error while verifying payment")
                    console.log(resp);
                }
            })
        .catch((err)=>{Toaster("error","Error in Subscription verification"); console.log(err); })
        .finally(()=>{setPaymentProcessing(false)})
    },[login,purchaser])

    const cancelSubscription=async(startNew=false)=>{
        setPaymentProcessing(true)
        
        await axiosInstance.post("payments/cancel-subscription",{
            cancel_sub_id: user.sub_id,
            rp_sub_id: user.rp_sub_id,
            status: user?.status
        })
        .then((resp)=>{
            if(resp.message==="success"){
                Toaster("success","Subscription Cancelled Successfully");
                login(resp.token);
                if(startNew)
                setModalState({state:true,title:"Modal Month choice",content:<ModalMonthChoice />})
            }else{
                Toaster("error","Error while cancelling Subscription")
                console.log(resp);
            }
        })
        .catch((err)=>Toaster("error",err))
        .finally(()=>setPaymentProcessing(false))
    }

    const ModalAlreadyPurchased=()=>{
       
        useEffect(()=>{
            Toaster("error","You need to cancel current subscription first")
        },[])
        
        return(
            <div className='bg-white text-black flex flex-col p-4 m-3 text-base font-semibold'>
                <ul>

                    <li>You've already purchased {user.company_plan}</li>
                    <li>You can wait till end of Current cycle which is 
                        <strong className='text-red-600'> {new Date(user.current_end).toDateString()} </strong>
                    or you cancel it now</li>
                    <li>By Clicking <strong>Buy Now</strong> you agree to
                        <strong> cancel the current Subscription immediately before payment </strong>
                        and create New subscription now</li>
                    <li>Your New subscription starts only after payment is successfull</li>
                </ul>
                <StdButton className={"bg-red-700 p-3 text-white py-4"} 
                    onClick={()=>cancelSubscription(true)}>
                    {paymentProcessing ? <span><Loader />Cancelling....</span> : "Cancel and Buy Now"}
                </StdButton>
            </div>
        )
    }
    const createSubscription = useCallback(({razorpay_plan_id,plan_name,months})=>{ // plan id of razorpay
        setPaymentProcessing(true)
        const {username,Email,phone_number} = user
        axiosInstance.post("payments/create-subscription",{
                            plan:{razorpay_plan_id},purchaser,months
                            }) 
                     .then((sub_response)=>{
                        if(sub_response.id){
                            const options = {
                                subscription_id: sub_response.razorpay_sub_id,//razorpay subscription id,
                                // key: "rzp_live_OPRxCbswSJKmVd",
                                key: "rzp_test_Ojo0n04TzOFq0o",
                                name: "Catermate AI",
                                description: plan_name, 
                                image: CatermateLogo,
                                handler: (sign)=>paymentVerification({...sign,monthsChecked:months}), // go to server for verification
                                prefill: {"name": username,"email": Email,
                                    "contact": "+"+phone_number,},
                                modal:{escape: false,ondismiss:()=>setPaymentProcessing(false)}
                            }

                            const razorpayObject = new window.Razorpay(options);
                            razorpayObject.open();
                            razorpayObject.on('payment.failed',function (response){
                                alert(response.error.description);
                                console.log(response);
                                setPaymentProcessing(false)
                            })
                        }else{
                            console.log(sub_response,"sub_response")
                        }
                     })
                     .catch((err)=>Toaster("error",err))
    },[paymentVerification,purchaser,user])

 
    const ModalMonthChoice=()=>{
        const [monthsChecked,setMonthsChecked] = useState()

        return(
            <div className='bg-white flex flex-col p-4 m-3'>
                <p>We Provide you option to choose how many months you want to subscribe</p>
                <div className="grid space-y-3">
                
                    <div className="relative flex items-start" onClick={()=>setMonthsChecked(1)}>
                        <div className="flex items-center h-5 mt-1">
                            <input id="hs-radio-1month" name="hs-radio-with-description" type="radio" 
                                className="border-gray-200 rounded-full text-blue-600 focus:ring-blue-500" 
                                    aria-describedby="hs-radio-1month-description" defaultChecked={monthsChecked===1} />
                        </div>
                        <label htmlFor="hs-radio-1month" className="ms-3">
                        <span className="block text-sm font-semibold text-gray-800">1 Month</span>
                        <span id="hs-radio-1month-description" className="block text-sm text-gray-600">We will charge you for current month only</span>
                        </label>
                    </div>
                    
                    <div className="relative flex items-start" onClick={()=>setMonthsChecked(12)}>
                        <div className="flex items-center h-5 mt-1">
                            <input id="hs-radio-month12" name="hs-radio-with-description" type="radio" 
                                className="border-gray-200 rounded-full text-blue-600 focus:ring-blue-500" 
                                    aria-describedby="hs-radio-12month-description" defaultChecked={monthsChecked===12} />
                        </div>
                        <label htmlFor="hs-radio-month12" className="ms-3">
                        <span className="block text-sm font-semibold text-gray-800">12 Months</span>
                        <span id="hs-radio-12month-description" className="block text-sm text-gray-600">
                            We will charge you for 12 months automatically , after that you can re-purchase the subscription
                        </span>
                        </label>
                    </div>
                
                </div>
                <StdButton className={"bg-red-700 p-3"} 
                    onClick={()=>{
                        setModalState({...modalInit})
                        createSubscription({...item,months:monthsChecked})
                    }}>
                    Buy Now
                </StdButton>

            </div>
        )
    }

    const triggerModal=()=>{
        let isAlreadyPurcahsed = Boolean(user?.sub_id)
        
        if(isAlreadyPurcahsed){
            setModalState({state:true,title:"Already Purchased", content: <ModalAlreadyPurchased />})
        }else{
            setModalState({state: true,title: "For How Many months you want to subscribe ?", content: <ModalMonthChoice />})
        }

    }


    const updatePayMentMethod=async()=>{
        setPaymentProcessing(true)
        if(user?.short_url){
            window?.open(user?.short_url,"UpdatePaymentMethod")
        }
        setPaymentProcessing(false)
    }


    return(
        <Fragment>
            <style>
                {
                    `
                    #pricing-cards button{
                     pointer-events: ${paymentProcessing ? "none" : "all"};
                    }
                    `
                }
            </style>
            
                <ScrollableModal modalState={modalState.state} title={modalState.title}
                    content={modalState.content}
                    onClose={()=>setModalState({...modalInit})}/>
            {
                isPurchased(item.plan_name) ?
                <div>
                    <button className={className} disabled={paymentProcessing} onClick={updatePayMentMethod}>
                        {
                            paymentProcessing ? <Loader /> : 
                            "Update Payment Method"
                        }
                    </button>
                    <button className={className} disabled={paymentProcessing} onClick={()=> {
                            if(window.confirm("Are you sure want to cancel , It will be cancelled immediately!")){
                                cancelSubscription()
                            }
                        }}>
                        {
                            paymentProcessing ? <Loader /> : 
                            "Cancel Subscription"
                        }
                    </button>
                </div>
                 :
                <button className={className}
                disabled={paymentProcessing} 
                onClick={()=>user ? triggerModal() : navigate("/login")}
                title={item.plan_name}>
                    {
                        paymentProcessing ?
                        <Loader />
                        :
                        "Buy Now"
                    }
                </button>
            }
        </Fragment>
    )
}

const PlanStatusChip=({status,sub_end})=>{

    const bgColor =  useMemo(() => {
        switch (status) {
            case "active":
                  return "bg-green-800 text-white"
        
            case "pending":
                  return "bg-yellow-500 text-black"
            
            case "halted":
                return "bg-red-600 text-white"
            case "completed":
                return "bg-gray-500 text-white"
            default:
                return "bg-gray-500 text-white"
        }
    }, [status])

    return(
        <Fragment>
            {
                status ? 
                <span className={`inline relative top-3 font-bold border border-gray-400 uppercase z-20 right-0 text-base m-3 rounded-3xl px-4 py-1  ${bgColor}`}>
                    {status}
                    {status==="completed" ? <sub className='m-2'>{diffbtwDates(new Date(),sub_end)}</sub> : null}
                </span>
                :null
            }
        </Fragment>
    )
}

const PricingPlans = ({subscriptionPage=[]}) => {
  
    const [serverPlans,setServerPlans] = useState([]);
    
    const {user} = useAuth();

    const userPlanStatus = useCallback((plan)=> plan===user?.company_plan ? user?.status : "" ,[user])

    const getServerPlans=async()=>{
        await axiosInstance.get("admin/plans")
                .then(setServerPlans)
                .catch((err)=>Toaster("error",err))
    }
    

    useEffect(()=>{
        getServerPlans()
    },[])
    
  return (
        <section id="subscribe" className={`relative  ${subscriptionPage ? "my-0" : "my-14"} p-4 mx-auto`}>
            <style>
                {
                    `
                    #subscribe ul{
                        list-style: disc;
                     }
                    `
                }
            </style>
            { !subscriptionPage.length &&
            <div className='mb-6'>
                <p className="text-center text-[#D51837] text-4xl font-bold">Pricing Plans</p>
                <p className="text-center font-medium text-lg text-wrap w-[70%] mx-auto">
                    Enhance your AI chatbot with more features, workflows, and automations through plug-and-play integrations.
                </p>
            </div>
            }
            <div id="pricing-cards" className="grid grid-flow-col grid-cols-9 max-xl:grid-cols-1 max-xl:grid-flow-row p-4 mx-[5%] justify-items-center">
                {
                    serverPlans?.map((item,index)=>{
                        if( !subscriptionPage.length || (!subscriptionPage?.includes(item.plan_name) ) )
                        return(
                            <div data-aos="zoom-in" key={item.plan_name} className={`relative col-span-${Math.floor(9/serverPlans.length)}`}>
                                <div 
                                    className={`w-[60%] top-8 h-[5rem] z-20 text-center  overflow-visible border-[10px] border-[#FEF1E1] mx-auto 
                                        rounded-2xl relative py-2 px-3 mb-1 text-white  bg-gradient-to-b ${item.bg_color}`}>
                                    <span className='text-3xl text-center font-bold'>₹{item.amount_in_paise / 100}</span>
                                    <sub className='text-[12px] block text-right'>
                                        /{item.plan_interval}{" "} 
                                        {item.plan_frequency === "daily" ? " days only" : item.plan_frequency} 
                                        {" "}{item.gst_percent}%gst
                                    </sub>
                                </div>
                                <PlanStatusChip status={userPlanStatus(item.plan_name)} sub_end={user?.sub_end}/>
                                <div className={`rounded-3xl text-center relative font-bold text-white max-w-[24rem] md:max-h-screen 
                                                    h-auto bg-gradient-to-b ${item.bg_color}  p-6 py-12`}>
                                    <p className="text-3xl font-semibold">{item.plan_name}</p> 
                                    <hr className="h-[2px] w-[80%] mx-auto bg-white"/>
                                    <div className="font-medium">Description & Benefits</div>
                                    <p className="w-[80%] text-wrap mx-auto">{item.description}</p>
                                    <hr className="h-[2px] w-[80%] mx-auto bg-white"/>
                                    <ul className="text-start ml-4 mt-6 px-14 w-[115%]">
                                        {
                                            item.perks.split(',').map((perk)=>{
                                                return(
                                                    <li key={perk}>{perk}</li>
                                                )
                                            })
                                        }
                                    </ul>
                                    <PurchaseButton  item={item}/>
                                </div>
                            </div>
                        )
                        else return null
                    })
                }
            </div>
            {!serverPlans.length && <div className='text-center mx-auto w-full'>We will update the pricing plans shortly</div>}
        </section>
  )
}

export default PricingPlans