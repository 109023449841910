import React from 'react'
import AboutUsImage from "@images/about_us.png";

const AboutUs = () => {
  return (
    <div className="lg:mx-[10%]  overflow-clip relative py-10 ">
    <section id="about-us" className="relative">
        <div className="relative h-fit max-h-[50vh] grid max-lg:grid-flow-row lg:grid-flow-cols lg:grid-cols-2 gap-x-8">
            <img src={AboutUsImage} alt="about us" className="z-20 absolute lg:left-[50%] lg:-bottom-[50%] max-lg:left-[45%] max-lg:-bottom-[55%] opacity-[0.5] scale-[0.6] lg:hidden"/>
            <div className="h-fit  lg:w-[43vw] max-lg:w-[100%] relative z-30">
                <div className="text-[#181818] font-light text-3xl p-2">About</div>
                <div className="text-[#C72122] text-5xl font-semibold">Catermate</div>
                <div className="text-[#181818] text-4xl font-semibold">All-in-One Catering <br className="lg:hidden"/> Management AI</div>
                <p className="max-w-[90%] font-medium">
                    Catermate is your dependable Catering Bot, designed to assist caterers in managing client needs seamlessly. 
                    From personalized menu recommendations to precise ingredient calculations, Catermate handles it all. Streamline your operations with efficiency and precision.
                </p>
            </div>
            <div className="max-lg:hidden h-fit w-fit scale-[0.7] relative bottom-[10%]">
                <img src={AboutUsImage} alt="about us" className=" relative"/>
            </div>
        </div>
    </section>
    </div>
  )
}

export default AboutUs