export const ManagerIcon=()=><svg width="26" height="36" viewBox="0 0 26 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.623 17.1094C20.1257 16.9503 18.6815 16.5136 18.6203 16.495C18.0706 16.3287 17.4868 16.6207 17.2901 17.1602L14.5666 24.6329L13.9259 20.1961L15.1523 18.0721C15.3457 17.7372 15.3457 17.3246 15.1523 16.9898C14.9589 16.6549 14.6017 16.4486 14.215 16.4486H11.4575C11.0707 16.4486 10.7135 16.655 10.5201 16.9898C10.3268 17.3247 10.3268 17.7373 10.5201 18.0721L11.7468 20.1967L11.1166 24.6627L8.38219 17.1602C8.18551 16.6207 7.60158 16.3284 7.05207 16.495C6.99079 16.5135 5.54659 16.9503 5.04928 17.1094C2.32575 17.9811 0.49585 20.5145 0.49585 23.4137V34.4177C0.49585 35.0154 0.98043 35.5 1.57814 35.5H24.0939C24.6917 35.5 25.1762 35.0154 25.1762 34.4177V23.4137C25.1764 20.5145 23.3464 17.9811 20.623 17.1094ZM2.66055 33.3354V23.4137C2.66055 21.4595 3.88568 19.7546 5.7091 19.171C5.91839 19.1039 6.31589 18.9816 6.69936 18.8645L11.8192 32.9122C11.8794 33.0774 11.9771 33.2212 12.1005 33.3355L2.66055 33.3354ZM23.0118 33.3353H13.5716C13.6951 33.221 13.7928 33.0771 13.8531 32.912L18.973 18.8644C19.3563 18.9815 19.754 19.1038 19.9632 19.1709C21.7866 19.7544 23.0118 21.4593 23.0118 23.4135V33.3353Z" fill="black"/>
<path d="M12.8359 13.9225C16.5365 13.9225 19.5472 10.9118 19.5472 7.21118C19.5472 3.5106 16.5366 0.5 12.8359 0.5C9.13524 0.5 6.12476 3.5106 6.12476 7.21118C6.12476 10.9118 9.13535 13.9225 12.8359 13.9225ZM12.8359 2.6647C15.3429 2.6647 17.3827 4.70426 17.3827 7.21118C17.3827 9.71811 15.343 11.7579 12.8359 11.7579C10.3289 11.7579 8.28946 9.71823 8.28946 7.21118C8.28946 4.70414 10.329 2.6647 12.8359 2.6647Z" fill="black"/>
</svg>

export const MenuIcon=()=><svg width="27" height="35" viewBox="0 0 27 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.5856 0.605451C12.3051 0.465134 11.9749 0.465134 11.6944 0.605451C11.0179 0.943858 10.4459 1.42198 10.0062 1.99527C9.56643 1.42198 8.99454 0.943858 8.31793 0.605451C8.03736 0.465134 7.70726 0.465134 7.42669 0.605451C6.75014 0.943858 6.17818 1.42198 5.73844 1.99527C5.2987 1.42198 4.72681 0.943858 4.05019 0.605451C3.76963 0.465134 3.43952 0.465134 3.15895 0.605451C1.50324 1.43367 0.474609 3.09795 0.474609 4.94882V12.1656C0.474609 14.7276 2.31453 16.868 4.74235 17.3349V31.3703C4.74235 33.0961 6.14644 34.5002 7.87228 34.5002C9.59811 34.5002 11.0022 33.0961 11.0022 31.3703V17.3349C13.43 16.868 15.2699 14.7276 15.2699 12.1656V4.94882C15.2699 3.09788 14.2414 1.43367 12.5856 0.605451ZM13.2778 12.1656C13.2778 13.9696 11.8101 15.4372 10.0061 15.4372C9.456 15.4372 9.01002 15.8832 9.01002 16.4333V31.3703C9.01002 31.9977 8.49962 32.5081 7.87228 32.5081C7.24494 32.5081 6.73454 31.9977 6.73454 31.3703V16.4333C6.73454 15.8832 6.28855 15.4372 5.73844 15.4372C3.93445 15.4372 2.4668 13.9696 2.4668 12.1656V4.94882C2.4668 4.03448 2.8924 3.19742 3.60454 2.66252C4.31668 3.19742 4.74228 4.03448 4.74228 4.94882V8.96482C4.74228 9.51493 5.18826 9.96091 5.73837 9.96091C6.28848 9.96091 6.73447 9.51493 6.73447 8.96482V4.94882C6.73447 4.03448 7.16007 3.19742 7.87221 2.66252C8.58435 3.19742 9.00995 4.03448 9.00995 4.94882V8.96482C9.00995 9.51493 9.45593 9.96091 10.006 9.96091C10.5562 9.96091 11.0021 9.51493 11.0021 8.96482V4.94882C11.0021 4.03448 11.4277 3.19742 12.1399 2.66252C12.852 3.19742 13.2776 4.03448 13.2776 4.94882V12.1656H13.2778Z" fill="black"/>
<path d="M18.5417 0.5C17.9916 0.5 17.5457 0.945985 17.5457 1.4961V31.3701C17.5457 33.0873 18.9336 34.5001 20.6756 34.5001C22.4255 34.5001 23.8055 33.0787 23.8055 31.3701V24.8977H26.0102C26.5603 24.8977 27.0063 24.4517 27.0063 23.9016V8.96349C27.0064 4.28941 23.2248 0.5 18.5417 0.5ZM25.0142 22.9055H22.8095C22.2594 22.9055 21.8134 23.3515 21.8134 23.9016V31.3701C21.8134 31.9916 21.3109 32.5079 20.6757 32.5079C20.0555 32.5079 19.5379 32.0064 19.5379 31.3701V2.56776C22.6005 3.03759 25.0142 5.68766 25.0142 8.96356V22.9055H25.0142Z" fill="black"/>
</svg>

export const EventIcon=()=><svg width="38" height="39" viewBox="0 0 38 39" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.3709 27.0338C17.5814 27.244 17.8667 27.3623 18.1644 27.3623C18.4618 27.3623 18.7471 27.2443 18.9576 27.0338L22.9634 23.028C23.4017 22.5897 23.4017 21.8794 22.9634 21.441C22.5253 21.0029 21.8147 21.0029 21.3767 21.441L18.1644 24.6533L16.6997 23.1886C16.2614 22.7506 15.5511 22.7506 15.1127 23.1886C14.6747 23.6267 14.6747 24.3373 15.1127 24.7756L17.3709 27.0338Z" fill="black"/>
<path d="M19.0383 33.1447C23.9498 33.1447 27.9454 29.1487 27.9454 24.2373C27.9454 19.3258 23.9498 15.3301 19.0383 15.3301C14.1265 15.3301 10.1309 19.3258 10.1309 24.2373C10.1309 29.1487 14.1268 33.1447 19.0383 33.1447ZM19.0383 17.5741C22.7124 17.5741 25.7014 20.5631 25.7014 24.2373C25.7014 27.9114 22.7124 30.9007 19.0383 30.9007C15.3642 30.9007 12.3748 27.9114 12.3748 24.2373C12.3748 20.5631 15.3642 17.5741 19.0383 17.5741Z" fill="black"/>
<path d="M33.9728 2.88921H31.9263V1.62198C31.9263 1.00243 31.4238 0.5 30.8043 0.5C30.1847 0.5 29.6823 1.00243 29.6823 1.62198V2.88921H27.4775V1.62198C27.4775 1.00243 26.9751 0.5 26.3552 0.5C25.7357 0.5 25.2332 1.00243 25.2332 1.62198V2.88921H12.8433V1.62198C12.8433 1.00243 12.3409 0.5 11.7211 0.5C11.1015 0.5 10.5991 1.00243 10.5991 1.62198V2.88921H8.39427V1.62198C8.39427 1.00243 7.89185 0.5 7.272 0.5C6.65245 0.5 6.15002 1.00243 6.15002 1.62198V2.88921H4.10321C2.10249 2.88921 0.474609 4.51709 0.474609 6.5181V34.8714C0.474609 36.8721 2.10249 38.5 4.10321 38.5H33.9728C35.9738 38.5 37.6014 36.8721 37.6014 34.8714V6.5181C37.6014 4.51709 35.9735 2.88921 33.9728 2.88921ZM35.3571 34.8714C35.3571 35.6348 34.7361 36.256 33.9728 36.256H4.10321C3.33986 36.256 2.71857 35.6348 2.71857 34.8714V12.219H35.3571V34.8714ZM2.71857 6.5181C2.71857 5.75446 3.33986 5.13345 4.10321 5.13345H6.15002V6.40068C6.15002 7.02023 6.65245 7.52266 7.27229 7.52266C7.89185 7.52266 8.39427 7.02023 8.39427 6.40068V5.13345H10.5991V6.40068C10.5991 7.02023 11.1015 7.52266 11.7214 7.52266C12.3409 7.52266 12.8433 7.02023 12.8433 6.40068V5.13345H25.2332V6.40068C25.2332 7.02023 25.7357 7.52266 26.3555 7.52266C26.9751 7.52266 27.4775 7.02023 27.4775 6.40068V5.13345H29.6823V6.40068C29.6823 7.02023 30.1847 7.52266 30.8043 7.52266C31.4241 7.52266 31.9265 7.02023 31.9265 6.40068V5.13345H33.9728C34.7361 5.13345 35.3574 5.75446 35.3574 6.5181V9.97478H2.71857V6.5181Z" fill="black"/>
</svg>

