import React, { useState, useEffect, useRef, Fragment, useCallback } from "react";
import "./CatermateDemo.css";
import CatermateDemo_USER_JSON from "constants/catermate_user_chat.json";
import CatermateDemo_ADMIN_JSON from "constants/catermate_admin_chat.json";
import CatermateAIMiniBot from "@images/catermate_ai_minibot.png";
import DownloadIcon from "@images/downloadIcon.png";
import EllipsisIcon from "@images/Ellipsis.svg";
import EllipsisVertical from "@images/EllipsisVertical.svg";
import adminReport from "constants/catermate_admin_report.pdf";
import userReport from "constants/catermate_user_report.pdf"

const USER_CHAT_HISTORY = CatermateDemo_USER_JSON.History;
const ADMIN_CHAT_HISTORY = CatermateDemo_ADMIN_JSON.History

const pdfs = {
    catermate_user_report : userReport,
    catermate_admin_report: adminReport
}


function CatermateDemo({id}) {
   
    const [messages, setMessages] = useState([]);
    const [currentUser, setCurrentUser] = useState("");
 
    const chatContainerRef = useRef();
    const [botLoading, setBotLoading] = useState(false);
    const [userLoading,setUserLoading] = useState(false);  
    const [pauseChat,setPauseChat] = useState({state: false,index:null});
    const [buttonText,setButtonText] = useState("Pause");

    const pauseChatRef = useRef(pauseChat);
    
    useEffect(()=>{
        pauseChatRef.current = {...pauseChat};
    },[pauseChat])
    
    const sendMessage =  useCallback((index,RESPECTIVE_CHAT ,pauseChatRefArg=pauseChatRef) => {
        
        if(pauseChatRefArg.current.state){
            setPauseChat((prev)=>({...prev,index:index}));
            return;
        }

        setMessages((prev)=>([...prev,RESPECTIVE_CHAT[index]]))

        if(RESPECTIVE_CHAT[index+1])
        {
            if(index%2 === 0){
                setBotLoading(true);
                setUserLoading(false);
            }
            else
            setUserLoading(true);
        }

        if(RESPECTIVE_CHAT[index+1] && !pauseChatRefArg.current.state){
            setTimeout(()=>{
                setBotLoading(false);
                setUserLoading(false);
                sendMessage(index+1,RESPECTIVE_CHAT);
            },3000);
        }else{
            setButtonText("Back To Menu");
            setPauseChat({state:false,index:null});
        }
    },[])

    const getCurrentChatHistory = useCallback((index,userchat) =>{        
        switch(userchat){
            case "admin":
                sendMessage(index,ADMIN_CHAT_HISTORY,pauseChatRef)
                break;
            case "user":
                sendMessage(index,USER_CHAT_HISTORY,pauseChatRef);
                break;
            default:
                break;
        }
    },[sendMessage])

   useEffect(()=>{
        getCurrentChatHistory(0,currentUser);
   },[currentUser,getCurrentChatHistory])

    useEffect(()=>{
        const element = chatContainerRef.current;
        if (element) {
            element.scrollTop = element.scrollHeight;
        }
    },[messages]);

    const bottomMenuButton=()=>{

        switch(buttonText){
            case "Pause":
                setPauseChat((prev)=>({...prev,state:true}))
                setButtonText("Resume");
                break;

            case "Resume":
                setPauseChat((prev)=>({...prev,state:false}));
                setTimeout(()=>{
                    getCurrentChatHistory(pauseChatRef.current.index,currentUser);
                },2000)
                setButtonText("Pause");
                break;

            case "Back To Menu":
                 setMessages([]);
                 setCurrentUser("");
                 setPauseChat({state:false,index:null});
                 setButtonText("Pause");
                break;  

            default:
                break;
        }
    }

    return (
        <div className="catermate-bot chefbot " style={{margin: id==="close-chat" ? 0 : null}}>
            <style>
                {
                    `
                        .catermate-bot + #chat-bubble{
                            display: none;
                        }
                    `
                }
            </style>
            <div className="Main-containers max-lg:w-[100%]" style={id==="close-chat" ? {margin:0,width: "100%",maxHeight:"95vh"} : null}>
                <div className="hederbotdiv bg-gradient-to-b from-[#c72122] to-[#fc8833]"> 
                    <span style={{ display: "inline", alignItems: "center" }}>
                        <img src={CatermateAIMiniBot} alt="catermate bot" className="cap inline w-10 h-10" />
                        <span className="headerbot text-2xl">Catermate AI</span>
                    </span>
                    <img src={EllipsisVertical} alt="ellipsis vertical" className="w-4 h-4 inline float-right mr-4 my-2"/>
                </div>
                <div className="chat-container lg:h-[24rem] xl:h-[70vh] px-[1rem] max-md:px-[0.2rem] max-xl:max-h-[40vh] max-2xl:max-h-[45vh] 2xl:max-h-[78vh]" ref={chatContainerRef}>
                    <div className="message-containerdiv ">
                        {messages.length === 0 && (
                            <div className="center-content">
                                <img src={CatermateAIMiniBot} alt="CatermateAIMiniBot" className="center-logo w-28 h-32" />
                                <p className="text-center text-xl text-[#C72122] font-semibold">Catermate Bot</p>
                                <div className="flex max-md:flex-wrap gap-1 mx-2 justify-center max-w-[80%] min-w-[-webkit-fill-available] m-7">
                                    <div className="font-semibold bg-gradient-to-b from-[#c72122] to-[#fc8833] rounded-lg p-4 mx-4 text-[white] cursor-pointer" 
                                        onClick={()=>{setCurrentUser("user")}}>
                                        Act as an End user
                                    </div>
                                    <div className="font-semibold bg-gradient-to-b from-[#c72122] to-[#fc8833] rounded-lg p-4 mx-4 text-[white] cursor-pointer"
                                        onClick={()=>{setCurrentUser("admin"); }}>
                                        Act as a Catermate admin
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="messages-container mb-5">
                            {messages.map((message, index) => {
                                if (message.sender === "bot") {
                                    return (
                                        <div key={message.message} className={`message ${message.sender} bot`}>
                                            <div>
                                                <span style={{ display: "inline-flex", alignItems: "center" }}>
                                                    <img src={CatermateAIMiniBot} alt="cap" className="cap" />
                                                    <span className="headtextwork">CaterMateBot</span>
                                                </span>
                                                <div className="message-content mt-1">
                                                    <pre className=" textfonts mb-1 mt-1 break-all hyphens-auto whitespace-pre-wrap" dangerouslySetInnerHTML={{__html:message.message}}>
                                                    </pre>
                                                    {
                                                        message.file ? 
                                                            <div className="cursor-pointer" onClick={()=>window.open(pdfs[message.file],"_blank")}>
                                                            <img src={DownloadIcon} alt="download" className="w-5 h-5 text-blue-800" />
                                                            <embed 
                                                                src={pdfs[message.file]}
                                                                type="application/pdf"
                                                                style={{maxHeight:"100px",overflow:"hidden",pointerEvents:"none"}}
                                                            />
                                                            </div>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    );
                                } else {
                                    return (<Fragment  key={message.message}>
                                            <div className="text-orange-400 font-bold mt-2 mr-1 block text-end ">
                                                {currentUser}
                                            </div>
                                        <div className={`message ${message.sender} mt-[0!important] You`}>
                                            <div className="message-content textfonts mb-1 mt-1 break-all hyphens-auto whitespace-pre-wrap">
                                                <p className="jv">{message.message}</p>
                                            </div>
                                            {
                                                message.file ? 
                                                <div className="cursor-pointer" onClick={()=>window.open(pdfs[message.file],"_blank")}>
                                                    <img src={DownloadIcon} alt="download" className="w-5 h-5 text-blue-800" />
                                                    <embed 
                                                        src={pdfs[message.file]}
                                                        type="application/pdf"
                                                        style={{maxHeight:"100px",overflow:"hidden",pointerEvents:"none"}}
                                                    />
                                                </div>
                                                : null
                                            }
                                        </div>
                                        </Fragment>
                                    );
                                }
                            })}
                            {botLoading && (
                                <Fragment>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <img src={CatermateAIMiniBot} alt="cap" className="cap" />
                                    <span className="headtextwork">CaterMateBot</span>
                                </div>
                                <div className="message bot">
                                    <div className="message-content">
                                        {/* <FontAwesomeIcon
                                            icon={faEllipsis}
                                            className="icon-sent2"
                                            beatFade
                                        /> */}
                                        <img src={EllipsisIcon} alt="ellipsis"/>
                                    </div>
                                </div>
                                </Fragment>
                            )}
                            {userLoading && (
                                <Fragment>
                                <div className="text-orange-400 font-bold mt-2 mr-1 block text-end ">
                                        {currentUser}
                                </div>
                                <div className="message You mt-[0!important]">
                                    <div className="message-content">
                                        {/* <FontAwesomeIcon
                                            icon={faEllipsis}
                                            className="icon-sent2"
                                            beatFade
                                        /> */}
                                        <img src={EllipsisIcon} alt="ellipsis"/>
                                    </div>
                                </div>
                                </Fragment>
                            )}
                        <button className={` ${messages.length === 0 && " hidden "} 
                                text-xl absolute bottom-[11%] left-[40%] mx-auto flex rounded-3xl 
                                groove bg-gradient-to-b from-[#c72122] to-[#fc8833] p-2 px-4 text-white
                                max-md:bottom-[4%]
                                `}
                            onClick={()=>bottomMenuButton()}
                            >
                            {buttonText}
                        </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CatermateDemo;
