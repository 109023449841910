import Footer from '@components/Footer'
import Header from '@components/Header'
import React, { Fragment } from 'react'

const TermsConditions = () => {
  return (
    <Fragment>
        <style>
            {
                `
                    ol {
                        list-style: numeric;
                    }
                    ul {
                        list-style: disc;
                    }
                    ol li {
                        margin: 3rem 0;
                    }

                   li ul li{
                        margin: 0.5rem 0;
                    }

                    #conditions-content{
                        font-size: 21px;
                    }
                    #conditions-content a{
                        text-decoration: underline;
                    }
                `
            }
        </style>
        <Header />
        <main className='relative top-28 pt-10 max-w-max mx-0 min-h-[90vh] mb-32'>
            <section id="terms-hero" className="min-w-[99vw] mx-1 py-1 relative">
                    <div style={{  position: 'relative',zIndex:5,margin: "0 35%" }}>
                        <p className='text-5xl font-semibold text-nowrap w-[100%] lg:mx-auto h-[-webkit-fill-available]'>
                            Terms and Conditions
                         </p>
                       
                    </div>
            </section>
            <section id="conditions-content" className="my-14">
                    <div className='w-[70%] mx-auto'>
                        Welcome to Catermate AI. By accessing or using our website and services, you are agreeing to these Terms and Conditions ("Terms") and 
                        entering into a legally binding agreement with Catermate AI. If you do not agree to these Terms, you should not use our services.
                    </div>
                    <ol className=" w-[70%] mx-auto">
                        <li>
                            <p className="font-semibold">Definitions</p>
                            <div>For the purpose of these Terms:</div>
                            <ul className="mx-[5%]">
                                <li>"Catermate AI" refers to the company operating the website and services.</li>
                                <li>"Services" include any offerings, features, or functionalities provided through the Catermate AI platform</li>
                                <li>"User" means any individual or entity using the website and/or services.</li>
                                <li>"Content" includes all information, data, text, graphics, videos, and other material provided on the website.</li>
                            </ul>
                        </li>
                        <li>
                            <p className="font-semibold">Eligibility</p>
                            <div>
                            The use of Catermate AI is intended for individuals who are at least 18 years old. By accessing our website,
                            you confirm that you are of legal age to form a binding contract and meet all eligibility requirements. 
                            Catermate AI reserves the right to restrict access based on jurisdiction or other factors.
                            </div>
                        </li>
                        <li>
                            <p className="font-semibold">User Obligations</p>
                            <div>Users are required to:</div>
                            <ul className="mx-[5%]">
                                <li>Ensure the accuracy of any information provided when creating an account or using our services.</li>
                                <li>Avoid sharing login credentials or other access details with unauthorized parties.</li>
                                <li>Comply with all applicable local, state, national, and international laws while using our website and services.</li>
                                <li>Avoid posting or transmitting any harmful, offensive, or illegal content, including content that violates intellectual property or privacy rights.</li>
                            </ul>
                            <div>
                                If Catermate AI finds any user in violation of these obligations, we reserve the right to suspend or terminate their access without notice.
                            </div>
                        </li>
                        <li>
                            <p className="font-semibold">Use of Services</p>
                            <div>Catermate AI provides its services for legitimate personal and professional use. By using our services, you agree not to:</div>
                            <ul className="mx-[5%]">
                                <li>Use automated systems (such as bots or crawlers) to access or interact with the website without permission.</li>
                                <li>Attempt to reverse engineer, decompile, or extract the source code of our services or content.</li>
                                <li>Reproduce, copy, sell, resell, or exploit any part of our services or content without prior written permission from Catermate AI.</li>
                            </ul>
                            <div>
                                Catermate AI may modify, update, or discontinue any aspect of the services at any time, with or without notice, at its sole discretion.
                            </div>
                        </li>
                        <li>
                            <p className="font-semibold">Intellectual Property</p>
                            <div>
                                All content, features, and functionalities (such as design, graphics, and layout) on Catermate AI are 
                                protected by copyright, trademark, patent, and other intellectual property laws. You agree not to:
                            </div>
                            <ul className="mx-[5%]">
                                <li>Copy, distribute, or create derivative works of our content.</li>
                                <li>Use our trademarks or branding without explicit written permission.</li>
                            </ul>
                            <div>Any unauthorized use of our intellectual property will be pursued to the fullest extent permitted by law.</div>
                        </li>
                        <li>
                            <p className="font-semibold">Third-Party Links and Services</p>
                            <div>
                                Our website may contain links to third-party websites or services that are not owned or controlled by Catermate AI. 
                                We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites. 
                                Users acknowledge that they access third-party sites at their own risk.
                            </div>
                        </li>
                        <li>
                            <p className="font-semibold">Privacy Policy</p>
                            <div>
                            Our Privacy Policy is incorporated by reference into these Terms. 
                            By using our website and services, you agree to the data practices outlined in our Privacy Policy, including the collection, use, and sharing of your information.
                            </div>
                        </li>
                        <li>
                            <p className="font-semibold">Disclaimer of Warranties</p>
                            <div>
                                Catermate AI provides all content and services "as is" and "as available" without warranties of any kind, either express or implied. 
                                We make no guarantees regarding the accuracy, completeness, or usefulness of the services, and disclaim liability for any errors or omissions.
                            </div>
                        </li>
                        <li>
                            <p className="font-semibold">Limitation of Liability</p>
                            <div>
                            In no event shall Catermate AI, its affiliates, directors, employees, or agents be liable for any indirect, incidental, special, consequential, or punitive damages, 
                            including but not limited to, loss of data, profits, or other intangible losses arising out of your use of or inability to use the services. 
                            This limitation of liability applies regardless of whether we were advised of the possibility of such damages.
                            </div>
                        </li>
                        <li>
                            <p className="font-semibold">Indemnification</p>
                            <div>
                            You agree to indemnify, defend, and hold harmless Catermate AI, its affiliates, employees, and agents from any claims, liabilities, damages, losses, costs, and expenses,
                             including reasonable legal fees, arising from your use of our services, your violation of these Terms, or your violation of any third-party rights.
                            </div>
                        </li>
                        <li>
                            <p className="font-semibold">Changes to the Terms</p>
                            <div>
                            Catermate AI reserves the right to modify these Terms at any time. We will notify you of any significant changes through email or by posting a notice on our website. 
                            Your continued use of our website or services after any such changes constitutes your acceptance of the revised Terms
                            </div>
                        </li>
                        <li>
                            <p className="font-semibold">Governing Law and Dispute Resolution</p>
                            <div>
                                These Terms and Conditions shall be governed by and construed in accordance with the laws of Tamilnadu. 
                                Any disputes arising out of or relating to these Terms shall be resolved through binding arbitration in Coimbatore, unless otherwise required by applicable law.
                            </div>
                        </li>
                        <li>
                            <p className='font-semibold'> Contact Us</p>
                            <ul className="mx-[5%]">
                                <li>Email: <a href="mailto:info@catermateai.com" target="_blank" rel="noreferrer">info@catermateai.com</a></li>
                                <li>Contact: <a href="tel:+919655445481">+91 96554 45481</a></li>
                            </ul>
                        </li>
                    </ol>
            </section>
        </main>
        <Footer />
    </Fragment>
  )
}

export default TermsConditions