import { Toaster } from '@constants/appConstants';
import { ManagerIcon,MenuIcon,EventIcon } from '@images/dashboardIcons';
import axiosInstance from 'apis/config';
import { useAuth } from 'features/contexts/AuthContext'
import React,{useState,useEffect, Fragment, useCallback} from 'react'
import { AgCharts } from 'ag-charts-react';

const NumberAnimation = ({ targetValue }) => {
  const [currentValue, setCurrentValue] = useState(0); // Start with 0
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (targetValue > currentValue) {
      setAnimate(true); // Trigger animation when target value changes
      const step = (targetValue - currentValue) / 50; // Divide the difference into steps
      let intervalId = setInterval(() => {
        setCurrentValue((prev) => {
          if (prev < targetValue) {
            return Math.min(prev + step, targetValue); // Increment the number
          } else {
            clearInterval(intervalId); // Stop when we reach the target
            return targetValue;
          }
        });
      }, 30); // Update the number every 30ms
      return () => clearInterval(intervalId); // Clean up interval on unmount or when value changes
    }
  }, [targetValue, currentValue]);

  return (
        <Fragment>
        <style>{`
        .count-up {
          display: inline-block;
          transition: all 0.3s ease-in-out;
        }

        .animate-countup {
          animation: count-up 1s ease-out forwards;
        }

        @keyframes count-up {
          0% {
            transform: scale(1.2);
          }
          100% {
            transform: scale(1);
          }
        }
      `}</style>
        {/* Animated number */}
        <span className={`count-up ${animate ? 'animate-countup' : ''}`}>
          {Math.round(currentValue)}
        </span>
        </Fragment>
   
  );
};


const AdminDashboard = () => {

  const {user} = useAuth();
  const [cardCounts,setCardCounts] = useState([
    {icon:ManagerIcon ,label: "Total Managers",value: "",key:"EmployeeCount"},
    {icon: MenuIcon,label: "Total Menu Items",value: "",key:"MenuCount"},
    {icon: EventIcon,label: "Total Events",value: "",key:"ConfirmedEventCount"}
  ])
  const [chartData,setChartData] = useState({lead:"",month:""})

  function updateCardData(prev,data){
    return prev.map((el)=>( {...el,value: data[el.key]} ));
  }

  const getDashboardData=useCallback(()=>{
    axiosInstance.get("dashboard/card-counts")
              .then((data)=>{
                setCardCounts((prev)=>updateCardData(prev,data))
              })
              .catch((err)=>Toaster("error",err))
    axiosInstance.get("dashboard/chart-data")
                  .then(setChartData)
                  .catch((err)=>Toaster("error",err))  
  },[])

  useEffect(()=>{
    getDashboardData()
  },[getDashboardData])

  return (
      <div className='mt-10 mx-auto w-[95%]'>  
      <p className='text-2xl'>Hello, <span className='font-semibold'>{user.username}</span> </p>
      <p>Here, you can easily manage, track, and analyze all your key data in one place.</p>
      <div className="grid grid-cols-10 my-2">
        <div className='grid grid-cols-6 col-span-6 gap-1'>
        
        {
          cardCounts.map((Card)=>{
            return(
              <div className='col-span-2  relative mx-3' key={Card.key}>
                <div className=' flex justify-center h-fit relative mb-1 top-10 z-20 bg-[#FFC092] text-center px-5 py-3 mx-[25%] overflow-visible border-x-[15px] border-y-[10px] border-[#FEF1E1] rounded-3xl'>
                {Card.icon({className:"mx-auto"})}
                </div>
                <div className='rounded-[40px]  text-center px-5 pt-14 relative font-bold  h-[10rem] bg-[#FFC092]'>
                  {Card.label}
                    <p className='mt-1 text-6xl block'>
                      <NumberAnimation targetValue={Card.value}/>
                    </p>
                </div>
              </div>
            )
          })
        }
        </div>
        {
          chartData.lead ? 
          <div className='col-span-6 m-3 rounded-full bg-white'>
          <AgCharts options={ 
          {
            data: [ {asset:"Converted as Clients",amount: parseInt(chartData.lead["Converted_as_Clients"]) },
                    {asset: "Remaining Leads",amount: parseInt(chartData.lead["Not_converted_as_clients"]) }],
            title: {text:"Lead Comparison"},
            series:[
              {
                type: "pie",
                angleKey: "amount",
                legendItemKey: "asset",
              },
            ]
          }} 
        />
          </div>
          : null
        }
        
        <div className='col-span-4'>
          {
            chartData.month ? 
            <div className='bg-white rounded-3xl'>
              <AgCharts options={
                {
                  data: chartData.month,
                  series: [{ type: "bar", xKey: "Order_Month", yKey: "Total_Orders" }],
                }
              }/>
            </div> :null
          }
        </div>
      </div>
      </div>
  )
}

export default AdminDashboard