import React, { useEffect, useRef, useState } from 'react'
import CatermateLogo from "@images/catermate_logo.jpg"
import LoginBot from "@images/login_bot.png";
import ErrorCircle from "@images/ErrorCircle.svg"
import { Loader, StdButton } from '@components/UtilityComponents';
import { useAuth } from 'features/contexts/AuthContext';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import axiosInstance from 'apis/config';
import { Toaster } from '@constants/appConstants';
import { PasswdEye } from '@images/sidebarIcons';

const initForm={email:"",emailError:false,otp:["","","","","",""],otpError:false,
                new_password:"",confirm_password:"",
                passwordError:false,confirmPasswordError:false,
                isOTPDisabled:true}

const ForgotPassword = () => {
    const [passwordResetForm,setPasswordResetForm] = useState({...initForm});
    const [loader,setLoader] = useState(false);
    const pinInputRef = useRef();

    const {isAuthenticated} = useAuth();
    const navigate= useNavigate();
    const handlePrelineReady=()=>{
        window?.HSPinInput?.autoInit()
        const el= window?.HSPinInput?.getInstance(document.querySelector("#pin-input"));
        
        el?.on("completed",({currentValue})=>{
            setPasswordResetForm((prev)=>({...prev,otp:currentValue}))
        })
    }
    
    useEffect(()=>{
        if( ! passwordResetForm.isOTPDisabled){
            handlePrelineReady();
            window?.HSTogglePassword?.autoInit()
        }
    },[passwordResetForm.isOTPDisabled]);

    useEffect(()=>{
        handlePrelineReady()
    },[])

    if(isAuthenticated){
        return <Navigate to="/" replace />
    }
    const handleChange=(e)=>{
        const {name,value} = e.target;
        setPasswordResetForm((prev)=>({...prev,[name]:value}))
    }

    const sendOTP=async()=>{
        setLoader(true)
        let resetFormCopy = {...passwordResetForm}
        resetFormCopy.emailError = !(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(passwordResetForm.email.trim()))
        if(resetFormCopy.emailError){
            
            setPasswordResetForm(resetFormCopy)
            setLoader(false)
            return;
        }
        await axiosInstance.post("user/send-otp",{email:passwordResetForm.email.trim()})
                     .then(()=>{
                        Toaster("success","Success , Please Enter OTP Sent to Your Registered Email")
                        setPasswordResetForm(()=>({...resetFormCopy,isOTPDisabled:false}))
                     })
                     .catch(()=>{
                        Toaster("error","Email Not found, Please Enter your Registered Email")
                     })
        setLoader(false)
    };

    const updatePassword=async()=>{
        setLoader(true)
        let resetFormCopy = {...passwordResetForm}
            resetFormCopy["otpError"] = passwordResetForm.otp.join("").length !== 6
        
            resetFormCopy["passwordError"] = passwordResetForm.new_password.trim().length <=6
        
            resetFormCopy["confirmPasswordError"] = passwordResetForm.new_password.trim() !== passwordResetForm.confirm_password.trim()
        
        if(["otpError","passwordError","confirmPasswordError"].some(el=>resetFormCopy[el])){
            setPasswordResetForm(resetFormCopy)
            setLoader(false)
            return;
        }

        await axiosInstance.post("user/reset-password",{
                otp: passwordResetForm.otp.join(""),
                new_password: passwordResetForm.new_password,
                email: passwordResetForm.email
                })
                .then(()=>{
                    Toaster("success","Password Resetted Successfully");
                    setPasswordResetForm({...initForm});
                    // setTimeout(()=>
                        navigate("/login")
                    // ,2000);
                })
                .catch(()=>{
                    Toaster("error","Invalid OTP")
                })
        setLoader(false)
    }

    

  return (
    <section id="forgot-password" className="grid grid-cols-2 grid-flow-col gap-0 rounded-full absolute m-auto top-0 bottom-0 left-0 right-0 min-w-36 min-h-36 max-h-fit max-w-fit">
        <div className="px-8 pt-3 pb-12 bg-white rounded-s-2xl max-lg:col-span-2">
                <img src={CatermateLogo} className="w-32 h-28" alt="logo"/>
                <h2 className="text-2xl font-medium">Forgot Password</h2>
                <p>Please enter your registered email</p>

                <div className='my-7'>
                    <div>
                        <div className="relative">
                        <input type="text" id="email" name="email" disabled={!passwordResetForm.isOTPDisabled}  placeholder="your@email.com" value={passwordResetForm.email} onChange={handleChange}
                        className={`py-3 px-4 block w-full outline-none rounded-lg border-[2px] border-[#FFC092]
                        ${ passwordResetForm.emailError && " border-red-500 rounded-lg  focus:border-red-500 focus:ring-red-500 "} `} 
                        required="" aria-describedby="email-helper" />
                        { passwordResetForm.emailError &&
                        <div className="absolute inset-y-0 end-0 flex items-center pointer-events-none pe-3">
                            <img src={ErrorCircle} alt="error"/>
                        </div>}
                        </div>
                        { passwordResetForm.emailError && <p className=" text-red-600 mt-2" id="email-helper">Please enter a valid email address.</p> }
                    </div>
                            <StdButton className=" flex mx-auto disabled:bg-slate-300" disabled={!passwordResetForm.isOTPDisabled || loader} onClick={sendOTP}>
                                {loader ? <Loader /> : "Send OTP"}
                            </StdButton>
                    <div className='w-full block mt-4'>Enter OTP PIN </div>
                    <div id="pin-input" ref={pinInputRef} className="flex gap-x-3 mx-auto border items-center justify-center rounded-lg p-3 w-[90%] border-[#FFC092]" data-hs-pin-input="">
                        {
                            passwordResetForm["otp"].map((value,index)=>{
                                
                                return (
                                    <input key={"otp_"+index}
                                    name={"otp_"+index}
                                    value={value}
                                    disabled={passwordResetForm.isOTPDisabled}
                                    className="block w-[38px] h-[38px] text-center outline-none border-2 border-gray-200 rounded-md text-sm [&::-webkit-outer-spin-button]:appearance-none 
                                                [&::-webkit-inner-spin-button]:appearance-none focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
                                    type="tel" placeholder="⚬" data-hs-pin-input-item="" autoComplete="one-time-code" />
                                
                                 )
                            })
                        } 
                    </div>
                    {
                        !passwordResetForm.isOTPDisabled &&
                        <form onSubmit={(e)=>e.preventDefault()}>
                            <div className="relative m-3">
                                <input id="hs-toggle-password-1" type="password" name="new_password" onChange={handleChange}
                                className={`py-3 ps-4 pe-10 block w-full border-[2px] border-[#FFC092] rounded-lg  outline-none  
                                                ${passwordResetForm.passwordError && " border-red-500 focus:border-red-500 focus:ring-red-500 "} `}
                                placeholder="New password" value={passwordResetForm.new_password}/>
                                <button type="button" data-hs-toggle-password='{
                                    "target": "#hs-toggle-password-1"
                                }' className="absolute inset-y-0 end-0 flex items-center bottom-1 z-20 px-3 cursor-pointer text-gray-400 rounded-e-md focus:outline-none focus:text-[#FFC092]">
                                <PasswdEye  className="shrink-0 size-3.5"/>
                                </button>
                                { passwordResetForm.passwordError && <div className='text-red-400'>Please enter a valid Password of atleast 6 characters</div>}
                            </div>
                            <div className="relative m-3">
                                <input id="hs-toggle-password-2" type="password" name="confirm_password" onChange={handleChange}
                                className={`py-3 ps-4 pe-10 block w-full border-[2px] border-[#FFC092] rounded-lg  outline-none  
                                                ${passwordResetForm.confirmPasswordError && " border-red-500 focus:border-red-500 focus:ring-red-500 "} `}
                                placeholder="Confirm password" value={passwordResetForm.confirm_password}/>
                                <button type="button" data-hs-toggle-password='{
                                    "target": "#hs-toggle-password-2"
                                }' className="absolute inset-y-0 end-0 flex items-center bottom-1 z-20 px-3 cursor-pointer text-gray-400 rounded-e-md focus:outline-none focus:text-[#FFC092]">
                                    <PasswdEye  className="shrink-0 size-3.5"/>
                                </button>
                                { passwordResetForm.passwordError && <div className='text-red-400'>Passwords do not match</div>}
                            </div>
                        </form>
                    }
                    { ! passwordResetForm.isOTPDisabled && 
                        <StdButton className={"flex mx-auto"} disabled={loader} onClick={updatePassword}>
                            {loader ? <Loader /> : "Verify OTP and Update Password"}
                        </StdButton>}
                </div>
                    <Link to="/login" className='underline'> &#129028; Back to Login</Link>
        </div>
        <div className="rounded-2xl max-lg:hidden">
            <img src={LoginBot} alt="login bot" className="h-full rounded-e-2xl"/>
        </div>
    </section>
  )
}

export default ForgotPassword