import React from 'react'
import Facebook from "@images/Facebook.png";
import Instagram from "@images/Instagram.png";
import LinkedIn from "@images/Linkedin.png";
import Twitter from "@images/Twitter.png";
import CatermateLogo from "@images/catermate_logo.jpg"
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="mt-14 py-3 max-xsm:left-[50%] max-xl:w-[100%]  bg-white rounded-t-3xl border-gray-400 border-2 relative bottom-0 xsm:left-[50.5%] -translate-x-1/2">
      <div className="mx-auto px-4 sm:px-6 lg:px-8 my-auto">
        <div className='grid grid-flow-col lg:grid-cols-3 max-lg:grid-cols-1 max-lg:grid-flow-row gap-y-3 lg:m-6 mb-[0!important]'>
           <p className="lg:w-fit text-center"><img src={CatermateLogo} alt="logo" className='w-20 h-14'/></p>
           <div className='flex justify-around font-semibold mx-[20%]'>
              <Link to="#home">Home</Link>
              <Link to="#about">About</Link>
              <Link to="#features">Features</Link>
              <Link to="#pricing">Pricing</Link>
           </div>
            <div className='flex lg:justify-end max-lg:justify-center gap-x-3 items-baseline'>
              <Link to="https://www.facebook.com/profile.php?id=61566580477309" target='_blank' rel="noreferrer">
                <img alt="catermate facebook" src={Facebook} className='w-5 h-5'/> 
              </Link>
              <Link to="https://x.com/Catermate_ai" target='_blank' rel="noreferrer">
                <img alt="catermate twitter" src={Twitter} className='w-5 h-5'/>
              </Link>
              <Link to="https://www.instagram.com/catermate.ai/" target='_blank' rel="noreferrer">
                <img alt="catermate instagram" src={Instagram} className='w-5 h-5'/>
              </Link>
              <Link to="https://www.linkedin.com/company/catermate-ai/?viewAsMember=true" target='_blank' rel="noreferrer">
                <img alt="catermate linkedin" src={LinkedIn} className='w-5 h-5'/>
              </Link>
            </div>
        </div>
        <hr className=' bg-gray-500 h-[2px]'/>
        <div className='flex justify-between'>
          <p className="max-xsm:text-[0.8rem]">&copy;{new Date().getFullYear()} Catermate AI. All Rights Reserved.</p>
          <div className='max-xsm:text-[0.8rem] flex justify-center'>
              <div>
                Contact: &#x2004;
              </div>
              <div className='font-bold'>
               mail: <Link to="mailto:info@catermateai.com" className='font-normal' target="_blank" rel="noreferrer">info@catermateai.com</Link>&#x2004;
              </div>
              <div className='font-bold'>
               tel: <Link to="tel:+919655445481" className='font-normal'>+91 96554 45481</Link>
              </div>
          </div>
          <div className="max-xsm:text-[0.8rem] flex justify-end">
            <Link to="/privacy-policy" target='_blank' rel="noreferrer">Privacy Policy</Link>
            <Link to="/terms-and-conditions" target="_blank" rel="noreferrer">• Terms & Conditions</Link>
            <Link to="/refund-policy" target='_blank' rel="noreferrer">• Refund Policy</Link>
          </div>
        </div>
        <div className='text-center m-3'>
          <Link className='underline' to="https://www.google.com/maps/search/?api=1&query=Neuronest+Ai+Pvt+Ltd" target='_blank' rel="noreferrer">
            Address: 3G, A Block , Pioneer Apartments, Coimbatore-641018, Tamilnadu
          </Link>
        </div>
      </div>
    </footer>
  )
}

export default Footer